<template>
  <div>
    <SendDocumentsTable
      :model="model"
      :items="beneficiariesChecklist"
      :config="config"
      :itemsPerPage="10">
      <template v-slot:[`item.benefit`]="{ item }">
        <span v-html="item.carrierName"></span>
      </template>
      <template v-slot:[`item.attachmentNumber`]="{ item }">
        {{
          isFamilyGroupChecklist(item)
          ? countBeneficiaryFiles(item)
          : item.checklistDocumentDTOS
            ? item.checklistDocumentDTOS.length
            : 0
        }} anexos
      </template>
      <template v-slot:[`item.fill`]="{ item }">
        <div style="width: 100%;" :id="item.id">
          <ProgressLoader
            v-if="isFamilyGroupChecklist(item)"
            :loaded="setBeneficiaryLoader(item.familyGroupDTOS)"
          />

          <ProgressLoader
            v-else
            :loaded="setLoader(item.checklistDocumentDTOS)"
          />
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <StatusBadge
          v-if="isFamilyGroupChecklist(item)"
          :label="setHeathStatus(item).label"
          :color="setHeathStatus(item).color"
        />

        <StatusBadge
          v-else
          :label="setTemplateStatus(item.checklistDocumentDTOS).label"
          :color="setTemplateStatus(item.checklistDocumentDTOS).color"
        />
      </template>
      <template v-slot:[`item.action`]="{ item, index }">
        <ActionBtn
          v-if="isFamilyGroupChecklist(item)"
          @click="openFamilyGroupModal(item, index)"
        />
        <ActionBtn
          v-else
          :disabled="
            !item.checklistDocumentDTOS
            || Array.isArray(item.checklistDocumentDTOS) && item.checklistDocumentDTOS.length === 0"
          @click="openLayoutModal(item)"
        />
      </template>
    </SendDocumentsTable>

    <Modal
      ref="BeneficiariesModal"
      :title="'Beneficiários'"
      @close="closeBeneficiariesModal">
      <template
        slot="body">
        <div
          style="min-height: 100px; max-height: calc(-270px + 100vh); overflow: auto;"
          class="pb-0">
          <ModalContentTable
            :items="beneficiaries"
            :config="modalConfig">
            <template v-slot:[`item.name`]="{ item }">
              <div
                class="d-flex align-center flex-row">
                <StatusTag
                  :status="setBeneficiaryHeathStatus(item).label"
                  :color="setBeneficiaryHeathStatus(item).color"
                  :icon="setBeneficiaryHeathStatus(item).icon"
                />

                <span class="ml-1">
                  {{ beneficiaryHolderName(item) }}
                </span>
              </div>
            </template>

            <template v-slot:[`item.dependentNumber`]="{ item }">
              <span>
                {{ item.quantityDependents === 0 ? 'Nenhum' : item.quantityDependents }}
                {{ item.quantityDependents > 1 ? 'dependentes' : 'dependente' }}
              </span>
            </template>

            <template v-slot:[`item.attachment`]="{ item }">
              <v-menu
                bottom
                right
                open-on-hover
                v-if="hasDocuments(item)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="secondary"
                    size="17"
                    v-on="on"
                    v-bind="attrs">
                    fas fa-paperclip
                  </v-icon>
                </template>

                <v-list
                  dense
                  style="background-color: #DCF0EE;"
                  class="py-0"
                  v-for="(document, i) in beneficiaryDocuments(item)"
                  :key="i">
                  <v-list-item
                    @click="downloadFile(document)">
                    <v-list-item-icon
                      class="mr-0 pr-0">
                      <v-icon
                        color="secondary"
                        size="17">
                        fas fa-paperclip
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-title
                      style="color: #44B7AB;"
                      class="d-flex justify-space-between ml-0 pl-0">
                      {{ document.name }}

                      <span
                        class="ml-4"
                        v-if="document.createDate">
                        {{ moment(document.createDate, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY, H:mm') }}
                      </span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

            <template v-slot:[`item.critics`]="{ item }">
              <div
                class="d-flex justify-start align-center">
                <v-img
                  style="cursor: pointer;"
                  :src="svgPath"
                  max-height="17px"
                  contain
                  v-if="hasBeneficiaryRiskDocumentsValidation(item) > 0"
                  @click="openBeneficiaryCriticsModal(item)"
                />
              </div>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <ActionBtn
                @click="editFamilyGroup(item)"
              />
            </template>
          </ModalContentTable>
        </div>
      </template>

      <template slot="action">
        <v-row
          class="d-flex justify-center my-1"
          style="background-color: #F6F2FC">
          <v-col cols="4" />
          <v-col cols="4" class="d-flex justify-center">
            <div
              @click="addFamilyGroup"
              style="cursor: pointer;">
              <ActionBtn
                :icon="'fas fa-plus'"
              />
              <span
                style="color: #5555A5; font-size: 14px;">
                Incluir Grupo Familiar
              </span>
            </div>
          </v-col>
          <v-col cols="4" class="d-flex justify-end align-center">
            Total: {{ totalBeneficiaries }} beneficiários
          </v-col>
        </v-row>
      </template>
    </Modal>

    <Modal
      ref="FamilyGroupModal"
      :title="'Inclusão'"
      @close="resetFamilyGroupModal">
      <template
        slot="body">
        <div
          style="min-height: 100px; max-height: calc(-270px + 100vh); overflow: auto;"
          class="pb-0 px-4">
          <v-form
            ref="FamilyGroupForm">
            <p
              class="my-4"
              style="font-size: 14px; font-weight: bold;">
              Dados do Titular
            </p>

            <v-row>
              <v-col
                cols="4"
                class="d-flex align-center">
                <v-text-field
                  label="Nome *"
                  outlined
                  v-model="familyGroup.holderName"
                  hide-details
                  @change="updateHolderName"
                  :rules="[formrules.required]"
                />
              </v-col>
            </v-row>

            <div
              v-for="(beneficiary, beneficiaryIndex) in familyGroup.beneficiaryDTOs"
              :key="`beneficiary-${beneficiaryIndex}`"
              :id="`beneficiary-${beneficiaryIndex}`">
              <v-row>
                <v-col
                  cols="12"
                  v-if="!beneficiary.holder && (!beneficiary.hasOwnProperty('delete') || beneficiary.delete !== true)">
                  <v-row>
                    <v-col
                      cols="12"
                      class="d-flex justify-start align-center">
                      <v-sheet
                        height="60"
                        width="100%"
                        color="#E8E8E8">
                        <v-container
                          fluid>
                          <v-row>
                            <v-col
                              class="d-flex align-center">
                              <span
                                class="font-weight-bold"
                                style="font-size: 16px;">
                                Dependente {{ beneficiaryIndex }}
                              </span>
                            </v-col>

                            <v-col
                              class="d-flex justify-end">
                              <v-tooltip
                                bottom
                                v-if="!beneficiary.holder">
                                <template
                                  v-slot:activator="{ on, attrs }">
                                  <ActionBtn
                                    :icon="trashIconPath"
                                    :attrs="attrs"
                                    :on="on"
                                    :useImage="true"
                                    class="mr-4"
                                    @click="requestConfirmRemoveDependent(beneficiaryIndex)"
                                  />
                                </template>
                                <span>Remover dependente</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-sheet>
                    </v-col>
                  </v-row>
                  <v-row
                    class="my-4">
                    <v-col
                      cols="4"
                      class="mb-0">
                      <v-text-field
                        v-if="!beneficiary.holder"
                        label="Nome *"
                        outlined
                        v-model="beneficiary.beneficiaryName"
                        hide-details
                        :rules="[formrules.required]"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                  cols="12"
                  class="py-0">
                  <div
                    v-for="(document, documentIndex) in beneficiary.checklistDocumentDTOS"
                    :key="`checklistDocumentDTOS-${documentIndex}`">
                    <v-container
                      fluid
                      v-if="!beneficiary.hasOwnProperty('delete') || beneficiary.delete !== true">
                      <v-row
                        class="d-flex align-center"
                        v-if="hasDeploymentDocument(document)">
                        <v-col
                          cols="6"
                          class="d-flex flex-row align-center">
                          <StatusTag
                            :status="checklistGroupStatus([deploymentDocument(document).statusCode]).label"
                            :color="checklistGroupStatus([deploymentDocument(document).statusCode]).color"
                            :icon="checklistGroupStatus([deploymentDocument(document).statusCode]).icon"
                          />

                          <p class="ml-1">
                            {{
                              document.name
                            }}
                          </p>
                        </v-col>

                        <v-col
                          cols="6"
                          class="d-flex justify-end">
                          <div
                            style="max-width: 280px; min-width: 280px;">
                            <FileDropzone
                              ref="FileDropzone"
                              v-if="!document.documentTypeField || document.documentTypeField === 'file'"
                              :isMultiple="false"
                              acceptFileTypes=".pdf,.png,.doc,.docx"
                              @showFileTypeMessageError="showFileTypeMessageError($event)"
                              @files="setFile(deploymentDocument(document), $event, document)"
                              :disabled="actionFieldValidation(deploymentDocument(document))"
                              height="40px"
                              actionBtnHeight="30px"
                              actionBtnWidth="30px"
                            />

                            <TextField
                              ref="TextField"
                              v-if="['smartphone', 'email', 'text'].includes(document.documentTypeField)"
                              :fieldValue="deploymentDocument(document).documentValue"
                              :attrs="{
                                title: setTitle(document),
                                rules: setRuleTypes(document),
                                vMask: setDocumentTypeFieldMask(document),
                              }"
                              @updateValue="setDocumentValue(deploymentDocument(document), $event, document)"
                              :disabled="actionFieldValidation(deploymentDocument(document))"
                              height="40px"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-divider v-if="!beneficiary.hasOwnProperty('delete') || beneficiary.delete !== true" />
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-form>

          <v-container
            fluid>
            <v-row>
              <v-col
                cols="12">
                <v-sheet
                  height="60"
                  width="100%"
                  color="#E8E8E8">
                  <v-container
                    fluid>
                    <v-row>
                      <v-col
                        class="d-flex flex-row align-center">
                        <ActionBtn
                          :icon="'fas fa-plus'"
                          @click="addDependent"
                        />

                        <span
                          class="ml-2 font-weight-medium"
                          style="color: #5555A5; font-size: 16px;">
                          Incluir dependente
                        </span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-sheet>
              </v-col>

              <v-col
                cols="12">
                <v-btn
                  large
                  outlined
                  color="primary"
                  class="rounded-lg elevation-1"
                  @click="openNewDocumentModal"
                  :disabled="!familyGroup || familyGroup.length === 0">
                  Inserir novo documento
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

          <div
            class="pt-2">
            <v-container
              fluid>
              <div
                class="pt-2 pb-7">
                <UserMessage
                  :message="`Para uma melhor organização, recomendamos
                    fazer o upload de cada documento conforme a ordem do
                    check-list.<br>Caso prefira, você também pode enviar
                    todos os arquivos de uma vez,
                    <span class='clickable' style='cursor: pointer; color: #5DAAF5; text-decoration: underline;'>clicando aqui</span>.`"
                  @click="openNewDocumentModal"
                />
              </div>
            </v-container>
          </div>
        </div>

        <v-divider />
      </template>

      <template slot="action">
        <div
            class="px-3 pt-2 pb-4" style="width: 100%">
          <v-container fluid>
            <v-row>
              <v-spacer />

              <v-btn
                  large
                  color="secondary"
                  class="black--text mr-6 rounded-lg"
                  width="200"
                  @click="closeFamilyGroupModal">
                Cancelar
              </v-btn>

              <v-btn
                  large
                  color="primary"
                  class="white--text rounded-lg"
                  width="200"
                  :disabled="
                  !familyGroup.beneficiaryDTOs
                  || familyGroup.beneficiaryDTOs
                  && familyGroup.beneficiaryDTOs.length === 0"
                  @click="setFamilyGroup(familyGroup)">
                Enviar
              </v-btn>
            </v-row>
          </v-container>
        </div>
      </template>
    </Modal>

    <Modal
      ref="LayoutModal"
      :title="'Beneficiários'"
      @close="resetAllFields">
      <template
        slot="body">
        <div
          style="min-height: 100px; max-height: calc(-270px + 100vh); overflow: auto;"
          class="pb-0">
          <v-form
            ref="LayoutForm">
            <ModalContentTable
              :items="documents"
              :config="layoutConfig">
              <template v-slot:[`item.name`]="{ item }">
                <div
                  class="d-flex align-center flex-row"
                  v-if="item && Array.isArray(item.deploymentDocuments)">
                  <StatusTag
                    :status="checklistGroupStatus([item.deploymentDocuments[0].statusCode]).label"
                    :color="checklistGroupStatus(setChecklistStatus(item)).color"
                    :icon="checklistGroupStatus(setChecklistStatus(item)).icon"
                  />

                  <span class="ml-1" v-html="item.name" />
                </div>
              </template>

              <template  v-slot:[`item.model`]="{ item }">
                <div
                  class="d-flex justify-start align-center">
                  <v-img
                    v-if="item.fileName"
                    style="cursor: pointer;"
                    :src="modelSvgPath"
                    max-height="20px"
                    contain
                    @click="downloadModel(item)"
                  />
                </div>
              </template>

              <template v-slot:[`item.attachment`]="{ item }">
                <v-menu
                  bottom
                  right
                  open-on-hover
                  v-if="hasDeploymentDocument(item) && item.deploymentDocuments[0].document">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="secondary"
                      size="17"
                      v-on="on"
                      v-bind="attrs">
                      fas fa-paperclip
                    </v-icon>
                  </template>

                  <v-list
                    dense
                    style="background-color: #DCF0EE;"
                    class="py-0">
                    <v-list-item
                      v-for="(document, i) in [item.deploymentDocuments[0].document]"
                      @click="downloadFile(document)"
                      :key="i"
                      class="py-0">
                      <v-list-item-icon>
                        <v-icon
                          color="secondary"
                          size="17">
                          fas fa-paperclip
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-title
                        style="color: #44B7AB;">
                        <span>
                          {{ document.name }}
                        </span>

                        <span
                          v-if="document.createDate"
                          class="ml-4">
                          {{ moment(document.createDate, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY, H:mm') }}
                        </span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <template v-slot:[`item.comments`]="{ item }">
                <div
                  class="d-flex justify-start align-center">
                  <v-icon
                    color="#8D8D8D"
                    size="17"
                    style="max-height: 17px;"
                    v-if="hasDeploymentDocument(item) && item.deploymentDocuments[0].statusCode === 'risk'"
                    @click="openCommentModal(item.deploymentDocuments[0])">
                    far fa-comment
                  </v-icon>
                </div>
              </template>

              <template v-slot:[`item.critics`]="{ item }">
                <div
                  class="d-flex justify-start align-center">
                  <v-img
                    style="cursor: pointer;"
                    :src="svgPath"
                    max-height="17px"
                    contain
                    v-if="
                      hasDeploymentDocument(item)
                      && item.deploymentDocuments[0].statusCode === 'risk'"
                    @click="openCriticsModal(item.deploymentDocuments, item)"
                  />
                </div>
              </template>

              <template v-slot:[`item.action`]="{ item }">
                <div
                  style="min-width: 280px;">
                  <FileDropzone
                    ref="FileDropzone"
                    v-if="
                      hasDeploymentDocument(item)
                      && (
                        !item.documentTypeField
                        || item.documentTypeField === 'file'
                      )"
                    :isMultiple="false"
                    :acceptFileTypes="acceptFileTypes"
                    @showFileTypeMessageError="showFileTypeMessageError($event)"
                    @files="setFile(item.deploymentDocuments[0], $event, item)"
                    :disabled="actionFieldValidation(item.deploymentDocuments[0])"
                    height="40px"
                    actionBtnHeight="30px"
                    actionBtnWidth="30px"
                  />

                  <TextField
                    v-if="['smartphone', 'email', 'text'].includes(item.documentTypeField)"
                    ref="Textfield"
                    :fieldValue="deploymentDocument(item).documentValue"
                    :attrs="{
                      title: setTitle(item),
                      rules: setRuleTypes(item),
                      vMask: setDocumentTypeFieldMask(item),
                    }"
                    :disabled="actionFieldValidation(item.deploymentDocuments[0])"
                    @updateValue="setDocumentValue(item.deploymentDocuments[0], $event, item)"
                    height="40px"
                  />
                </div>
              </template>
            </ModalContentTable>
          </v-form>

          <v-divider />

          <div
            class="px-3 pt-2">
            <v-container
              fluid>
              <v-row>
                <v-spacer />

                <v-btn
                  large
                  color="secondary"
                  class="black--text mr-6 rounded-lg"
                  width="200"
                  @click="closeModal">
                  Cancelar
                </v-btn>

                <v-btn
                  large
                  color="primary"
                  class="white--text rounded-lg"
                  width="200"
                  :disabled="checklistPayload.length === 0"
                  @click="updateChecklist">
                  Enviar
                </v-btn>
              </v-row>
            </v-container>
          </div>
        </div>
      </template>
    </Modal>

    <CommentModal
      ref="CommentModal"
      :isCombo="false"
    />

    <CriticsModal
      ref="CriticsModal"
    />

    <NewDocumentModal
      ref="NewDocumentModal"
      @updateNewDocument="updateNewDocument($event)"
    />

    <v-snackbar
      v-model="snackbar">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

    <DialogDeleteConfirmation
      :show="showDeleteDialog"
      :text="dialogDeleteText"
      @showDialog="showDeleteDialog = $event"
      @confirmDialog="removeDependent"
    />
  </div>
</template>

<script>
/* eslint-disable */
import DialogDeleteConfirmation from '../../components/assessment/dialogDeleteConfirmation.vue';
import ProgressLoader from '../../components/structure/progressLoader.vue';
import FileDropzone from '../../components/file-dropzone/fileDropzone.vue';
import AttachmentMixin from '../atendimento/functions/attachmentMixin';
import StatusBadge from '../../components/structure/statusBadge.vue';
import UserMessage from '../../components/structure/userMessage.vue';
import RequestMixin from '@/view/atendimento/functions/requestMixin';
import DocumentsMixins from '../../shared/mixins/documentsMixins';
import TextField from '../../components/structure/textField.vue';
import StatusTag from '../../components/structure/statusTag.vue';
import DocumentService from '@/services/odoo/documentService';
import SendDocumentsTable from './sendDocumentsTable.vue';
import Modal from '../../components/structure/modal.vue';
import ModalContentTable from './modalContentTable.vue';
import NewDocumentModal from './newDocumentModal.vue';
import CriticsModal from './criticsModal.vue';
import CommentModal from './commentModal.vue';
import ActionBtn from './actionBtn.vue';
import { mapGetters, mapMutations } from 'vuex';
import { formrules } from '../../common/formrules';
import moment from 'moment';

import { IMPLANTATION_MAX_FILE_SIZE_MB } from "@/utils/constants";

export default {
  name: "BeneficiariesTable",

  components: {
    DialogDeleteConfirmation,
    SendDocumentsTable,
    ModalContentTable,
    NewDocumentModal,
    ProgressLoader,
    CriticsModal,
    CommentModal,
    FileDropzone,
    StatusBadge,
    UserMessage,
    ActionBtn,
    TextField,
    StatusTag,
    Modal,
  },

  data: () => ({
    formrules,
    model: [],
    familyGroup: {},
    documents: [],
    checklistPayload: [],
    checklistDocuments: [],
    documentService: null,
    creatingFamilyGroup: false,
    carrierChecklistId: null,
    financialGroupId: null,
    snackbar: false,
    snackbarText: null,
    outherDocuments: [],
    limitFileSize: IMPLANTATION_MAX_FILE_SIZE_MB,
    acceptFileTypes: ".xls,.xlsx,.csv,.txt",
    showDeleteDialog: false,
    dialogDeleteText: null,
    dependentIndex: null,
    moment,
  }),

  mixins: [
    DocumentsMixins,
    AttachmentMixin,
    RequestMixin,
  ],

  methods: {
    ...mapMutations(["loading", "showmsg", "setChecklist"]),

    async downloadModel(item) {
      await this.downloadModelByDocumentTypeId(item);
    },

    hasDocuments(item) {
      const documents = item.beneficiaryDTOs.filter(
        (beneficiary) => beneficiary.checklistDocumentDTOS.some(
          (checklist) => checklist.deploymentDocuments.some(
            (document) => document && document.document != null)));

      return documents && documents.length > 0 ? true : false;
    },

    beneficiaryDocuments(item) {
      let documents = []

      item.beneficiaryDTOs.forEach(
        (beneficiary) => beneficiary.checklistDocumentDTOS.some(
          (checklist) => checklist.deploymentDocuments.some(
            (document) => {
            if (document && document.document != null) {
              documents.push(document.document)
            }
      })));

      return documents && documents.length > 0 ? documents : [];
    },

    openNewDocumentModal() {
      this.$refs.NewDocumentModal.open();
    },

    cleanFamilyGroupModal() {
      this.outherDocuments = [];

      if (this.$refs.FileDropzone) {
        this.$refs.FileDropzone.forEach((ref) => {
          if (ref) ref.cleanAllFiles();
        })
      }

      if (this.$refs.TextField) {
        this.$refs.TextField.forEach((ref) => {
          if (ref) ref.reset();
        })
      }

      this.cleanFamilyGroup();
    },

    closeBeneficiariesModal() {
      this.carrierChecklistId = null;
      this.$forceUpdate();
    },

    closeFamilyGroupModal() {
      this.$refs.FamilyGroupModal.close();
      this.resetFamilyGroupModal();
    },

    resetFamilyGroupModal() {
      this.cleanFamilyGroupModal();
    },

    async getChecklistDocuments() {
      await this.documentService.GetChecklistDocuments()
        .then((response) => response.data)
        .then((result) => this.checklistDocuments = result)
        .catch((err) => {
          this.showmsg({text: "Ocorreu um erro ao buscar o checklist!", type: 'error' });
          console.error(err);
        });
    },

    addFamilyGroup() {
      this.creatingFamilyGroup = true;

      const holderChecklistDocuments = this.checklistDocuments.filter(
        (el) => el.beneficiary_document_use === 'holder');
      const checklistDocumentDTOSObj = this.setChecklistDocumentDTOSObj(holderChecklistDocuments);

      const holderObj = {
        beneficiaryName: null,
        checklistDocumentDTOS: checklistDocumentDTOSObj,
        holder: true,
      };

      this.familyGroup = {
        beneficiaryDTOs: [holderObj],
        holderName: null,
        quantityBeneficiaries: 0,
      };

      this.openFamilyGroup();
    },

    setChecklistDocumentDTOSObj(checklistDocuments) {
      let checklists = [];

      checklistDocuments.forEach((checklistDocument) => {
        checklists.push({
          id: checklistDocument.id,
          documentTypeField: checklistDocument.document_return_type_code,
          name: checklistDocument.name,
          deploymentDocuments: [{
            id: null,
            customerId: null,
            carrierName: null,
            status: "Pendente",
            statusCode: "pending",
            document: null,
            analysis: null,
            lastRiskDate: null,
            documentValue: null
          }]
        });
      })

      return checklists;
    },

    resetAllFields() {
      if (this.$refs.FileDropzone) {
        if (Array.isArray(this.$refs.FileDropzone)) {
          this.$refs.FileDropzone.forEach((ref) => {
            if (ref) ref.cleanAllFiles();
          });
        } else {
          this.$refs.FileDropzone.cleanAllFiles();
        }
      }

      if (this.$refs.TextField) {
        if (Array.isArray(this.$refs.TextField)) {
          this.$refs.TextField.forEach((ref) => {
            if (ref) ref.reset();
          });
        } else {
          this.$refs.TextField.reset();
        }
      }

      setTimeout(() => {
        this.documents = [];
        this.checklistPayload = [];
      }, 500);

      this.$forceUpdate();
    },

    closeModal() {
      this.resetAllFields();
      this.$refs.LayoutModal.close();
    },

    openFamilyGroupModal(item, index) {
      this.documents = [];

      this.title = item.carrierName;
      this.carrierChecklistId = item.carrierChecklistId;
      this.$forceUpdate();

      this.$refs.BeneficiariesModal.open();
    },

    openLayoutModal(item) {
      this.documents = item.checklistDocumentDTOS;
      this.$refs.LayoutModal.open();
    },

    setBeneficiaryLoader(familyGroup) {
      let allAttachedDocuments = 0;
      let allDocuments = 0;
      let result = 0;

      if (familyGroup && familyGroup.length > 0) {
        familyGroup.forEach((beneficiary) => {
          beneficiary.beneficiaryDTOs.forEach((beneficiary) => {
            const attachedDocuments = beneficiary.checklistDocumentDTOS.filter(
              (documentDTO) =>
                this.hasDeploymentDocument(documentDTO)
                && documentDTO.deploymentDocuments.some(
                  (deploymentDocument) =>
                    !['pending', 'risk'].includes(deploymentDocument.statusCode)));

            beneficiary.checklistDocumentDTOS.forEach((checklist) => {
              allDocuments += checklist.deploymentDocuments.length;
            })

            allAttachedDocuments += attachedDocuments.length;
          })
        });
      }

      if (!Number.isNaN(allAttachedDocuments / allDocuments)) {
        result = (allAttachedDocuments / allDocuments) * 100;
      }

      return result;
    },

    getFamilyDocuments(family) {
      let familyGroup = [];

      family.beneficiaryDTOs.forEach((beneficiary) => {
        familyGroup = [...familyGroup, ...beneficiary.checklistDocumentDTOS]
      });

      return familyGroup;
    },

    async openBeneficiaryCriticsModal(item) {
      let riskDocuments = [];

      if (item.beneficiaryDTOs && item.beneficiaryDTOs.length > 0) {
        for (const beneficiaryDTO of item.beneficiaryDTOs) {
          for (const checklist of beneficiaryDTO.checklistDocumentDTOS) {
            for (const documentDTO of checklist.deploymentDocuments) {
              if (documentDTO.statusCode === 'risk') {
              const criticsHistory = await this.getCriticsHistory(documentDTO, checklist);

                riskDocuments = [...riskDocuments, ...criticsHistory];
              }
            }
          }
        }
      }

      this.$refs.CriticsModal.open(riskDocuments);
    },

    async openCriticsModal(deploymentDocuments, item) {
      let riskDocuments = [];

      if (deploymentDocuments && deploymentDocuments.length > 0) {
        for (const checklist of deploymentDocuments) {
          if (checklist.statusCode === 'risk') {
            const criticsHistory = await this.getCriticsHistory(checklist, item);

            riskDocuments = [...riskDocuments, ...criticsHistory];
          }
        }
      }

      this.$refs.CriticsModal.open(riskDocuments);
    },

    hasBeneficiaryRiskDocumentsValidation(item) {
      let riskDocuments = [];

      if (item.beneficiaryDTOs && item.beneficiaryDTOs.length > 0) {
        item.beneficiaryDTOs.forEach((beneficiaryDTO) =>
          beneficiaryDTO.checklistDocumentDTOS.forEach((checklist) =>
            checklist.deploymentDocuments.forEach((documentDTO) => {
            if (documentDTO.statusCode === 'risk') {
              riskDocuments.push(documentDTO);
            }
        })))
      }

      return riskDocuments.length;
    },

    openFamilyGroup() {
      this.$refs.FamilyGroupModal.open();
    },

    editFamilyGroup(item) {
      this.familyGroup = item;

      this.openFamilyGroup();
    },

    updateHolderName() {
      const beneficiaryIndex = this.familyGroup.beneficiaryDTOs.findIndex(
        (beneficiary) => beneficiary.holder === true);

      this.familyGroup.beneficiaryDTOs[beneficiaryIndex].beneficiaryName = this.familyGroup.holderName;
    },

    async setFamilyGroup(item) {
      if(!this.$refs.FamilyGroupForm.validate()) {
        this.showmsg({text: "Preencha os campos obrigatórios do formulário", type: 'error' });
        return;
      };

      let hasInvalidFiles = false;

      for (let beneficiary of item.beneficiaryDTOs) {
        for (let checklist of beneficiary.checklistDocumentDTOS) {
          for (let document of checklist.deploymentDocuments) {
            if (document.fileContent) {
              if (this.fileSizeValidation([document.fileContent])) {
                hasInvalidFiles = true;
              };
            }
          }
        }
      }

      if (hasInvalidFiles) return;

      try {
        this.loading(true);

        const payload = await this.setFamilyGroupPayload(item);

        await this.documentService.CreateFamilyGroup(payload)
          .then(async (response) => {
            await this.getChecklistDocumentsByFinancialGroupId();
            this.resetFamilyGroupModal();
            this.closeFamilyGroupModal();
          })
          .catch((err) => {
            const errorMessage = `Não foi possivel ${this.creatingFamilyGroup ? 'criar' : 'atualizar'} o grupo familiar!`;
            this.showmsg({ text: errorMessage, type: "error" });
            console.error(err)
          })
          .finally(() => this.loading(false));
      } catch(err) {
      } finally {
        this.loading(false);
      }
    },

    beneficiaryHolderName(item) {
      let holderName = item.holderName;

      if (!item.holderName) {
        const beneficiaryHolderIndex = item.beneficiaryDTOs.findIndex((el) => el.holder === true);

        if (beneficiaryHolderIndex !== -1) {
          holderName = item.beneficiaryDTOs[beneficiaryHolderIndex].beneficiaryName;
        }
      }
      return holderName;
    },

    async setFamilyGroupPayload(item) {
      let beneficiaries = [];

      for (const beneficiary of item.beneficiaryDTOs) {
        let beneficiariesPayload = await this.setBeneficiariesPayload(beneficiary);

        if (beneficiary.holder === true) {
          const outherDocuments = this.outherDocuments.map((doc) => ({
            datas: doc.fileContent,
            name: doc.fileName,
            description: "CLIENT",
          }));

          const newDocument = {
            document_type_id: {
              id: this.outhersDocumentTypeId
            },
            attachment_ids: outherDocuments
          }

          beneficiariesPayload.deployment_document_ids.push(newDocument);
        }

        beneficiaries.push(beneficiariesPayload);
      }

      return {
        id: item.hasOwnProperty('familyGroupId') ? item.familyGroupId : null,
        beneficiary_holder_name: item.holderName,
        quantity_beneficiary: item.beneficiaryDTOs.length,
        deployment_checklist_id: this.carrierChecklistId,
        deployment_document_by_beneficiary_ids: beneficiaries,
      };
    },

    async setBeneficiariesPayload(beneficiaryDTO) {
      let documents =  [];

      for (const documentDTO of beneficiaryDTO.checklistDocumentDTOS) {
        const documentPayload = await this.setDocumentsPayload(documentDTO);

        documents.push(documentPayload)
      }

      return {
        id: beneficiaryDTO.hasOwnProperty('beneficiaryId') ? beneficiaryDTO.beneficiaryId : null,
        beneficiary_name: beneficiaryDTO.beneficiaryName,
        holder: beneficiaryDTO.holder,
        delete: beneficiaryDTO.delete && beneficiaryDTO.delete === true ? true : false,
        deployment_document_ids: documents,
      };
    },

    async setDocumentsPayload(documentDTO) {
      const documentContent = this.deploymentDocument(documentDTO);

      if (documentContent.fileContent) {
        const attachment = this.mountAttachment(documentContent.fileContent);

        const fileContent = await Promise.all([attachment]);

        return {
          attachment_ids: fileContent,
          document_type_id: {
            id: documentDTO.id,
          },
          carrier_checklist_id: this.carrierChecklistId,
        };
      } else {
        return {
          document_value: documentContent.documentValue ? documentContent.documentValue : null,
          document_type_id: {
            id: documentDTO.id,
          },
          carrier_checklist_id: this.carrierChecklistId,
        };
      }
    },

    setDocumentValue(item, documentValue, dto) {
      item.id = item.id;
      item.fileContent = null;
      item.documentValue = documentValue;

      if (!this.isFamilyGroupChecklist(dto)) {
        this.updatePayload(item, dto);
      }
    },

    updatePayload(documentDTO, checklistDocumentDTO) {
      const dtoIndex = this.checklistPayload.findIndex((checklist) => checklist.ids.some((id) => id === documentDTO.id));

      const isFile = !checklistDocumentDTO.documentTypeField || checklistDocumentDTO.documentTypeField === 'file';

      if (dtoIndex === -1) {
        this.checklistPayload.push({
          ids: [documentDTO.id],
          fileContent: documentDTO.fileContent ? documentDTO.fileContent : null,
          fileName: isFile ? documentDTO.fileContent && documentDTO.fileContent.name ? documentDTO.fileContent.name : null : null,
          documentValue: !isFile ? documentDTO.documentValue : null,
        })
      } else {
        if (!documentDTO.documentValue && !documentDTO.fileContent) {
          this.checklistPayload.splice(dtoIndex, 1);
          return;
        }

        this.checklistPayload[dtoIndex].deployment_carrier_document = documentDTO.id;

        if (isFile) {
          this.checklistPayload[dtoIndex].fileContent = documentDTO.fileContent;
          this.checklistPayload[dtoIndex].documentValue = null;
        } else {
          this.checklistPayload[dtoIndex].fileContent = null;
          this.checklistPayload[dtoIndex].documentValue = documentDTO.documentValue;
        }
      }
    },

    async updateChecklist() {
      if(!this.$refs.LayoutForm.validate()) {
        this.showmsg({text: "Preencha os campos obrigatórios do formulário", type: 'error' });
        return;
      };

      let hasInvalidFiles = false;

      for (let checklist of this.checklistPayload) {
        if (checklist.fileContent) {
          if (this.fileSizeValidation([checklist.fileContent])) {
            hasInvalidFiles = true;
          }
          checklist.fileContent = await this.mountAttachment(checklist.fileContent);

          checklist.fileContent = checklist.fileContent.datas;
        }
      }

      if (hasInvalidFiles) return;

      this.loading(true);

      await this.documentService.UpdateChecklist(this.checklistPayload)
        .then(async () => {
          await this.getChecklistDocumentsByFinancialGroupId();
          this.closeModal();
        })
        .finally(() => this.loading(false));
    },

    fileSizeValidation(files) {
      let sizeValidationFailed = false;
      files.forEach((file) => {
        if (file.size > ( this.limitFileSize * 1000000 )) {
          this.snackbarText = `Arquivo ${file.name} esta ultrapassando tamanho máximo por arquivo de ${this.limitFileSize}MB`;
          this.snackbar = true;
          this.loading(false);
          sizeValidationFailed = true;
        }
      });

      return sizeValidationFailed;
    },

    setFile(item, file, dto) {
      item.id = item.id;
      item.documentValue = null;
      item.fileContent = file;

      if (!this.isFamilyGroupChecklist(dto)
        && (!file || (Array.isArray(file) && file.length === 0))) {
        this.removeChecklistFile(item)
      } else {
        this.updatePayload(item, dto);
      }
    },

    setDocumentTypeFieldMask(item) {
      switch(item.documentTypeField) {
        case 'smartphone':
          return  "(##) #####-####";
        default:
          return false;
      }
    },

    setTitle(item) {
      switch(item.documentTypeField) {
        case 'email':
          return "E-mail";

        case 'smartphone':
          return "Celular";

        default:
          return "Link";
      }
    },

    setRuleTypes(item) {
      if (!item.deploymentDocuments[0].documentValue) return [true];

      switch(item.documentTypeField) {
        case 'email':
          return  [this.formrules.email];

        case 'smartphone':
          return  [this.formrules.cellphone];

        default:
          return [true];
      }
    },

    setTextFieldMask(item) {
      switch(item.documentTypeField) {
        case 'email':
          return "E-mail";

        case 'smartphone':
          return "Celular";

        default:
          return "Link";
      }
    },

    cleanFamilyGroup() {
      setTimeout(() => {
        this.creatingFamilyGroup = false;
        this.familyGroup = {};
      }, 500);
    },

    async updateNewDocument(documentType) {
      if (!this.$refs.NewDocumentModal.validate()) return;;

      let payload = [];

      let hasInvalidFiles = false;

      if (documentType.files) {
        if (Array.isArray(documentType.files)) {
          for (let file of documentType.files) {
            if (this.fileSizeValidation([file])) {
              hasInvalidFiles = true;
            };

            const attachment = await this.mountAttachment(file);

            payload.push({
              ids: documentType.ids,
              fileContent: attachment.datas,
              fileName: file.name,
              documentValue: null,
              documentTypeId: this.outhersDocumentTypeId,
            });
          }
        } else {
          documentType.files = await this.mountAttachment(documentType.files);

          documentType.files = documentType.files.datas;
        }
      } else {
        payload.push({
          ids: documentType.ids,
          fileContent: null,
          fileName: null,
          documentValue: documentType.documentValue,
        });
      }

      if (hasInvalidFiles) return;

      this.outherDocuments = payload;

      this.$refs.NewDocumentModal.close();

      // this.loading(true);

      // await this.documentService.UpdateChecklist(payload)
      //   .finally(() => {
      //     this.loading(false);
      //     this.$refs.NewDocumentModal.close();
      //   });
    },

    addDependent() {
      const newDependent = this.setNewDependent();

      this.familyGroup.beneficiaryDTOs.push(newDependent);

      const beneficiaryIndex = this.familyGroup.beneficiaryDTOs.length-1;

      const beneficiaryRefName = `beneficiary-${beneficiaryIndex}`;

      setTimeout(() => {
        const newBeneficiaryElement = document.getElementById(beneficiaryRefName);

        newBeneficiaryElement.scrollIntoView({ behavior: 'smooth' });
      }, 500)
    },

    requestConfirmRemoveDependent(dependentIndex) {
      this.dependentIndex = dependentIndex;
      this.dialogDeleteText = "Você deseja realmente excluir este Dependente?";
      this.showDeleteDialog = true;
    },

    removeDependent() {
      this.showDeleteDialog = false;

      const dependentToRemove = this.familyGroup.beneficiaryDTOs[this.dependentIndex];

      if (dependentToRemove && dependentToRemove.new === true) {
        this.familyGroup.beneficiaryDTOs.splice(this.dependentIndex, 1)
      } else {
        this.familyGroup.beneficiaryDTOs[this.dependentIndex].delete = true;
      }
      this.dialogDeleteText = null;
      this.dependentIndex = null;
      this.$forceUpdate();
    },

    removeChecklistFile(item) {
      const checklistIndex = this.checklistPayload.findIndex((checklist) => checklist.ids.some((id) => id === item.id));

      if (checklistIndex !== -1) {
        this.checklistPayload.splice(checklistIndex, 1);
        this.$forceUpdate();
      }
    },

    setNewDependent() {
      const checklist = this.setChecklistObject();

      return {
        beneficiaryName: null,
        checklistDocumentDTOS: checklist,
        holder: false,
        new: true,
      }
    },

    setChecklistObject() {
      const dependentChecklistDocuments = this.checklistDocuments.filter(
        (el) => el.beneficiary_document_use === 'dependent');

      const checklistDocumentDTOS = this.setChecklistDocumentDTOSObj(dependentChecklistDocuments);

      return checklistDocumentDTOS
    },

    deploymentDocument(document) {
      return document.deploymentDocuments[0];
    },

    isFamilyGroupChecklist(item) {
      return Array.isArray(item.familyGroupDTOS);
    },
  },

  computed: {
    ...mapGetters(["getChecklist"]),

    svgPath() {
      return "/img/icons/" + process.env.VUE_APP_ACCOUNT_APP_CODE + "/exclamation.svg";
    },

    modelSvgPath() {
      return "/img/icons/" + process.env.VUE_APP_ACCOUNT_APP_CODE + "/download-model.svg";
    },

    trashIconPath(){
      return "/img/icons/" + process.env.VUE_APP_ACCOUNT_APP_CODE + "/trash.svg";
    },

    beneficiaries() {
      if (!this.carrierChecklistId) return [];

      const recipientDoc = this.getChecklist.find(
        (checklist) => checklist.checklistLevelCode === 'RECIPIENT_DOC');
      const beneficiaryCarrierChecklist = recipientDoc.levelDTOS.find(
        (dto) => dto.carrierChecklistId === this.carrierChecklistId);

      return beneficiaryCarrierChecklist.familyGroupDTOS;
    },

    totalBeneficiaries() {
      let amount = 0;

      this.beneficiaries.forEach((familyGroup) => {
        amount += familyGroup.quantityBeneficiaries;
      });

      return amount;
    },

    beneficiariesChecklist() {
      const recipientDoc = this.getChecklist.find((checklist) => checklist.checklistLevelCode === 'RECIPIENT_DOC');
      const recipientLayout = this.getChecklist.find((checklist) => checklist.checklistLevelCode === 'RECIPIENT_LAYOUT');

      const recipientDocFiltered = recipientDoc.levelDTOS.filter(
        (dto) =>
          Array.isArray(dto.familyGroupDTOS)
          && dto.familyGroupDTOS.length > 0);

      const recipientLayoutFiltered = recipientLayout.levelDTOS.filter(
        (dto) =>
          Array.isArray(dto.checklistDocumentDTOS)
          && dto.checklistDocumentDTOS.length > 0);


      const beneficiaries = [
        ...recipientDocFiltered,
        ...recipientLayoutFiltered
      ];

      return beneficiaries && beneficiaries.length > 0 ? beneficiaries : [];
    },

    config() {
      return {
        headers: [
          {
            text: 'Benefício',
            value: 'benefit',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 4,
              xl: 4,
            }
          },
          {
            text: 'Nº de anexos',
            value: 'attachmentNumber',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: '% de Preenchimento',
            value: 'fill',
            align: 'start',
            class: 'text--truncate',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Status',
            value: 'status',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Ação',
            value: 'action',
            align: 'end',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
        ]
      }
    },

    modalConfig() {
      return {
        headers: [
          {
            text: 'Titular',
            value: 'name',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 3,
              xl: 3,
            }
          },
          {
            text: 'Nº de Dependentes',
            value: 'dependentNumber',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 3,
              xl: 3,
            }
          },
          {
            text: 'Anexos',
            value: 'attachment',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Críticas',
            value: 'critics',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Ação',
            value: 'action',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
        ]
      }
    },

    layoutConfig() {
      return {
        headers: [
          {
            text: 'Nome',
            value: 'name',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Modelo',
            value: 'model',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Anexos',
            value: 'attachment',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 6,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Comentários',
            value: 'comments',
            align: 'start',
            class: 'text--truncate',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 2,
              xl: 2,
            }
          },
          {
            text: 'Críticas',
            value: 'critics',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              sm: 6,
              md: 4,
              lg: 1,
              xl: 2,
            }
          },
          {
            text: 'Ação',
            value: 'action',
            align: 'start',
            attrs: {
              class: 'text--truncate',
              cols: 12,
              md: 4,
              lg: 3,
              xl: 2,
            }
          },
        ]
      }
    },
  },

  created() {
    this.documentService = new DocumentService();
  },

  async mounted() {
    this.financialGroupId = this.$util.getSession()
      && this.$util.getSession().financialGroup
        ? this.$util.getSession().financialGroup.id
        : null;

    await this.getChecklistDocuments();

    await this.getChecklistTypeOuthers();
  },
}
</script>