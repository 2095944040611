<template>
  <v-snackbar
    content-class="pr-0 py-0"
    v-model="msgshow"
    :color="`${msg.data.type} lighten-1`"
    :timeout="msg.timeout"
    rounded="lg"
    top
  >
    <v-container
      fluid
      class="pa-0"
    >
      <v-row
        no-gutters
      >
        <v-col
          class="py-3 d-flex align-center"
        >
          <div
            style="text-align: justify;"
            class="white--text"
            v-html="$util.sanitize(msg.data.text)"
          />
        </v-col>

        <v-col
          class="d-flex justify-center py-3"
          style="max-width: 70px;"
        >
          <v-btn
            text
            icon
            @click="hidemsg()"
          >
            <v-icon
              :color="`${msg.data.type} lighten-4`"
            >
              fas fa-times
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState(["msg"]),
    msgshow: {
      get: function() {
        return this.msg.show;
      },
      set: function(value) {
        this.tooglemsg(value);
      }
    }
  },
  methods: {
    ...mapMutations(["tooglemsg", "hidemsg"])
  }
};
</script>
