import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"fill-height",staticStyle:{"padding-bottom":"80px","background-color":"#F0F0F0"},attrs:{"fluid":""}},[_c(VCard,{staticClass:"fill-height elevation-4 white rounded-xl pa-8",attrs:{"width":"100%"}},[_c(VRow,[_c(VCol,{attrs:{"xl":"3","lg":"3"}},[_c(VImg,{staticStyle:{"border":"2px solid #E4E4E4"},attrs:{"height":"123","contain":"","src":_vm.onboardingLogo}})],1),_c(VCol,{staticClass:"d-flex flex-column",attrs:{"xl":"6","lg":"6"}},[_c('p',{staticClass:"mb-5 font-weight-medium text-subtitle-1",domProps:{"innerHTML":_vm._s(_vm.welcomeText)}})]),_c(VCol,{staticClass:"d-flex align-end flex-column",attrs:{"xl":"3","lg":"3"}},[_c('p',{staticClass:"mb-5 font-weight-bold"},[_vm._v(" Proposta Técnica Comercial ")]),_c(VBtn,{staticClass:"black--text rounded-lg text-subtitle-1 font-weight-bold px-6 d-flex justify-space-between",staticStyle:{"height":"44px","max-width":"230px !important","width":"230px !important"},attrs:{"color":"secondary","width":"230","loading":_vm.proposalBtnLoading},on:{"click":_vm.downloadProposal}},[_c(VImg,{attrs:{"max-height":"23","max-width":"23","contain":"","src":_vm.proposalIconPath()}}),_c('span',{staticClass:"text-capitalize text-subtitle-1 front-weight-bold"},[_vm._v(" Visualizar Proposta ")])],1)],1)],1),_c(VRow,_vm._l((_vm.proposals),function(proposal,index){return _c(VCard,{key:`proposal-${index}`,staticClass:"elevation-4 rounded-xl mb-4",attrs:{"width":"100%","min-height":"230"}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"fill-height px-4"},[_c(VCol,{class:{
                  'd-flex align-items-space-between justify-space-between flex-wrap': _vm.$vuetify.breakpoint.lgAndUp,
                  'd-flex align-start justify-space-between flex-wrap': _vm.$vuetify.breakpoint.mdAndDown,
                },staticStyle:{"max-width":"300px"},attrs:{"xl":"3","lg":"3"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(proposal.carrierName)+" ")]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(proposal ? !Number.isNaN(_vm.calculePercentage(proposal)) && Number.isInteger(_vm.calculePercentage(proposal)) ? _vm.calculePercentage(proposal) : _vm.calculePercentage(proposal).toFixed(2) : 0)+"% ")])]),_c('div',{staticStyle:{"height":"30px","width":"100%"}},[_c('ProgressLoader',{attrs:{"loaded":_vm.calculePercentage(proposal),"hideLoader":true}})],1),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v(" Início da Vigência: ")]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.moment(proposal.initialDate, "YYYY-MM-DD").format("DD/MM/YYYY"))+" ")])]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v(" Finalização do Projeto: ")]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.moment(proposal.finalDate, "YYYY-MM-DD").format("DD/MM/YYYY"))+" ")])])]),_c(VBtn,{class:{
                  'rounded-lg mb-2': true,
                  'mt-auto': _vm.$vuetify.breakpoint.lgAndUp
                },attrs:{"large":"","outlined":"","color":"#5555A5"},on:{"click":function($event){return _vm.openTimelineModal(proposal)}}},[_c('span',{staticClass:"text-capitalize text-subtitle-1 front-weight-bold"},[_vm._v(" Detalhar Cronograma ")])])],1),_c(VCol,{attrs:{"xl":"9","lg":"9","cols":"12"}},[_c('Stepper',{attrs:{"steps":proposal.steps}})],1)],1)],1)],1)}),1)],1),_c('TimelineModal',{ref:"TimelineModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }