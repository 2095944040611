<template>
  <div>
    <v-text-field
      v-if="!attrs.vMask"
      v-bind="attrs"
      :disabled="disabled"
      outlined
      dense
      v-model="text"
      :class="{
        'textfield-styles': !!attrs.title,
        'rounded-lg v-input--is-focused v-aplication': true,
        'gray--text': disabled,
        'primary--text': !disabled,
      }"
      hide-details
      @focus="applyFocus"
      @focusout="focus = false">
      <template
        v-slot:prepend-inner
        class="ma-0 pa-0">
        <div
          class="d-flex align-center justify-center v-aplication v-primary--text prepend-styles rounded-l-lg"
          :style="`border-right: 2px solid; background-color: #F6F2FC; height: ${height}`">
          <span>
            {{ attrs.title }}
          </span>
        </div>
      </template>
    </v-text-field>

    <v-text-field
      v-else
      v-bind="attrs"
      :disabled="disabled"
      outlined
      dense
      v-model="text"
      :class="{
        'textfield-styles': !!attrs.title,
        'rounded-lg v-input--is-focused v-aplication': true,
        'gray--text': disabled,
        'primary--text': !disabled,
      }"
      hide-details
      @focus="applyFocus"
      @focusout="focus = false"
      v-mask="attrs.vMask">
      <template
        v-slot:prepend-inner
        class="ma-0 pa-0">
        <div
          class="d-flex align-center justify-center v-aplication v-primary--text prepend-styles rounded-l-lg"
          :style="`border-right: 2px solid; background-color: #F6F2FC; height: ${height}`">
          <span>
            {{ attrs.title }}
          </span>
        </div>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: "TextField",

  data: () => ({
    focus: false,
    text: null,
  }),

  props: {
    attrs: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "56px"
    },
    fieldValue: {
      type: String,
      default: ""
    },
  },

  watch: {
    text: {
      handler(newValue) {
        this.$emit('updateValue', newValue);
      }
    },
    fieldValue: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue && newValue !== this.text) {
          this.text = newValue;
        }
      },
    }
  },

  methods: {
    applyFocus() {
      setTimeout(() => {
        this.focus = true
      }, 250);
    },

    reset() {
      this.text = null;
    },
  }
}
</script>

<style>
.prepend-styles {
  width: 80px;
}
.textfield-styles {
  > .v-input__control {
    > .v-input__slot {
      padding: 0 12px 0 0 !important;

      > .v-input__prepend-inner {
        margin: 0 !important;
        height: 100%;
      }
    }
  }
}
</style>