import { mapMutations, mapState } from "vuex";
import moment from "moment";

export default {
  data: () => ({
    statusMapping: {
      completed: { color: "#CDF9D9", labelColor: "#154B24", label: "Concluído", iconColor: "#62C756", icon: "fas fa-check", },
      pending: { color: "#FAEEBD", labelColor: "#866113", label: "Pendente", iconColor: "#E8E8E8", icon: "" },
      risk: { color: "#FAEEBD", labelColor: "#866113", label: "Crítica", iconColor: "#E0523F", icon: "fas fa-times" },
      digital: { color: "#E0EFFF", labelColor: "#14497C", label: "Digitalização", iconColor: "#5DAAF5", icon: "fas fa-check" },
      cancel: { color: "#F7BEB8", labelColor: "#7C1C0F", label: "Cancelado", iconColor: "#E0523F", icon: "fas fa-times" },
      analysis: { color: "#E0EFFF", labelColor: "#14497C", label: "Em análise", iconColor: "#5DAAF5", icon: "fas fa-check" },
      xipp_validity: { color: "orange", labelColor: "#14497C", label: "Validado Xipp", iconColor: "orange", icon: "fas fa-check" },
    },
    financialGroupId: null,
  }),

  methods: {
    ...mapMutations(["setCurrentMenu", "showmsg"]),

    showFileTypeMessageError(acceptFileTypes) {
      this.showmsg({text: `Apenas os arquivos do tipo: ${acceptFileTypes} são permitidos, os demais tipos serão ignorados`});
    },

    setStepperIcon(code) {
      return `/img/icons/` + process.env.VUE_APP_ACCOUNT_APP_CODE + `/${code}.svg`;
    },

    goToSendDocuments() {
      const messageError = "Você não tem permissão de acessar a página Envio de Documentos.<br>Entre em contato com a equipe de suporte!"
      const menu = this.session.menu.find(
        (menu) => menu.code === "envioDocumentos"
        || menu.link === "envioDocumentos"
        || ((menu.code === "onboarding" || menu.code === "onboarding")
          && menu.subMenus.some(
            (sub) => sub.code === 'envioDocumentos' || sub.link === 'envioDocumentos'))
      );

      if (!menu) {
        this.showmsg({
          text: messageError,
          type: 'error'
        });
        return;
      }

      const sendDocumentsMenu = menu.code === 'onboarding'
        ? menu.subMenus.find((sub) => sub.code === 'envioDocumentos')
        : menu;

      if (sendDocumentsMenu) {
        this.setCurrentMenu(sendDocumentsMenu);
        this.$router.push("/envioDocumentos");
      } else {
        this.showmsg({
          text: messageError,
          type: 'error'
        });
      }
    },

    setStatusChecklist(code) {
      return {
        icon: this.statusMapping[code].icon,
        color: this.statusMapping[code].iconColor,
      }
    },

    setTimeLineStatus(item) {
      const deliveryDate = item.deliveryDate ? moment(item.deliveryDate, "YYYY-MM-DD") : false;
      const finishedDate = item.finishedDate ? moment(item.finishedDate, "YYYY-MM-DD") : false;

      if (item.statusCode === 'completed' && deliveryDate.isBefore(finishedDate)) {
        return {
          icon: this.statusMapping['risk'].icon,
          color: this.statusMapping['risk'].iconColor,
        }
      }

      return this.setStatusChecklist(item.statusCode);
    },

    setStatusColor({ label, code, color }) {
      if (code && this.statusMapping[code]) {
        return this.statusMapping[code].color;
      }

      if (label) {
        const match = Object.values(this.statusMapping).find(
          (status) => status.label === label
        );
        return match ? match.color : this.statusMapping.pending.color;
      }

      if (color) {
        const match = Object.values(this.statusMapping).find(
          (status) => status.color === color
        );
        return match ? match.labelColor : this.statusMapping.pending.labelColor;
      }

      return this.statusMapping.pending.color;
    },

    countBeneficiaryFiles(familyGroup) {
      let count = 0;

      familyGroup.familyGroupDTOS.forEach((familyGroup) =>
        familyGroup.beneficiaryDTOs.forEach((beneficiary) =>
          beneficiary.checklistDocumentDTOS.forEach((checklist) => {
            const files = checklist.deploymentDocuments.map((item) => item.statusCode);

            count = count + files.length;
      })));

      return count;
    },

    setHeathStatus(familyGroup) {
      let label = "Pendente";
      let color = this.setStatusColor({code: 'pending'});

      let documentStatus = [];

      if (familyGroup.familyGroupDTOS.length > 0) {
        familyGroup.familyGroupDTOS.forEach((familyGroupDTO) =>
          familyGroupDTO.beneficiaryDTOs.forEach((beneficiary) =>
            beneficiary.checklistDocumentDTOS.forEach((checklist) => {
              const status =  checklist.deploymentDocuments.map((item) => item.statusCode);

              documentStatus = [...documentStatus, ...status];
            })));
      }

      if (documentStatus && documentStatus.length > 0) {
        let res = this.setFamilyGroupStatus(documentStatus)

        label = res.label;
        color = res.color;
      }

      return {
        label: label,
        color: color
      }
    },

    setBeneficiaryHeathStatus(familyGroupDTO) {
      let label = "Pendente";
      let color = this.setStatusChecklist('pending').color;
      let icon = this.setStatusChecklist('pending').icon;

      let documentStatus = [];

      familyGroupDTO.beneficiaryDTOs.forEach((beneficiary) =>
        beneficiary.checklistDocumentDTOS.forEach((checklist) => {
          const status = checklist.deploymentDocuments.map((item) => item.statusCode)
          documentStatus = [...documentStatus, ...status];
      }));

      let res = this.checklistGroupStatus(documentStatus)

      label = res.label;
      color = res.color;
      icon = res.icon;

      return {
        label: label,
        color: color,
        icon: icon,
      }
    },

    checklistGroupStatus(documentStatus) {
      let label = null;
      let color = null;
      let icon = null;
      let statusCode = null;

      switch(true) {
        case documentStatus.includes("risk"):
          label = "Com crítica";
          color = this.setStatusChecklist('risk').color;
          icon = this.setStatusChecklist('risk').icon;
          statusCode = "risk";
        break;

        case documentStatus.includes("pending"):
          label = "Pendente";
          color = this.setStatusChecklist('pending').color;
          icon = this.setStatusChecklist('pending').icon;
          statusCode = "pending";
        break;

        case documentStatus.some((status) => ["analysis", "digital"].includes(status)):
          label = "Em Validação";
          color = this.setStatusChecklist('analysis').color;
          icon = this.setStatusChecklist('analysis').icon;
          statusCode = "analysis";
        break;

        case documentStatus.some((status) => ["cancel", "completed", "xipp_validity"].includes(status)):
          label = "Validado";
          color = this.setStatusChecklist('completed').color;
          icon = this.setStatusChecklist('completed').icon;
          statusCode = "completed";
        break;
      }

      return {
        label: label,
        color: color,
        icon: icon,
        statusCode: statusCode,
      }
    },

    setFamilyGroupStatus(documentStatus) {
      let label = "Pendente";
      let color = this.setStatusColor({code: 'pending'});

      switch(true) {
        case documentStatus.includes("risk"):
          label = "Com crítica";
          color = this.setStatusColor({code: 'risk'});
        break;

        case documentStatus.includes("pending"):
          label = "Pendente";
          color = this.setStatusColor({code: 'pending'});
        break;

        case documentStatus.some((status) => ["analysis", "digital"].includes(status)):
          label = "Em Validação";
          color = this.setStatusColor({code: 'analysis'});
        break;

        case documentStatus.some((status) => ["cancel", "completed", "xipp_validity"].includes(status)):
          label = "Validado";
          color = this.setStatusColor({code: 'completed'});
        break;
      }

      return {
        label: label,
        color: color,
      }
    },

    setTemplateStatus(documents) {
      let label = "Pendente";
      let color = this.setStatusColor({code: 'pending'});

      if (documents) {
        let documentStatus = [];

        documents.forEach((document) => {
          const checklistDocumentStatusCode = document.deploymentDocuments.map((checklistDocument) => checklistDocument.statusCode)
          documentStatus = [...documentStatus, ...checklistDocumentStatusCode];
        });

        let res = this.setFamilyGroupStatus(documentStatus)

        if (res) {
          label = res.label;
          color = res.color;
        }
      }

      return {
        label: label,
        color: color
      }
    },

    actionFieldValidation(item) {
      return ['analysis', 'completed', 'digital', 'xipp_validity', 'cancel'].includes(item.statusCode);
    },

    hasDeploymentDocument(item) {
      return item && item.deploymentDocuments && item.deploymentDocuments.length > 0;
    },

    setChecklistStatus(item) {
      if (!item) return ["pending"];

      const statusDocuments = item.deploymentDocuments.map((checklist) => checklist.statusCode);
      return statusDocuments;
    },

    setLoader(documents) {
      let amount = 0;
      let result = 0;

      if (!documents || documents.length === 0) return 0;

      const attachedDocuments = documents.filter(
        (documentDTO) =>
          this.hasDeploymentDocument(documentDTO)
          && documentDTO.deploymentDocuments.some(
            (deploymentDocument) => !['pending', 'risk'].includes(deploymentDocument.statusCode)));

      amount = attachedDocuments && attachedDocuments.length > 0 ? attachedDocuments.length : 0;

      if (!Number.isNaN(amount / documents.length)) {
        result = (amount / documents.length) * 100;
      }

      return result;
    },

    openCommentModal(item) {
      this.$refs.CommentModal.open(item);
    },
  },

  computed: {
    ...mapState(["session"]),

    getDocumentSvg() {
      return "/img/icons/" + process.env.VUE_APP_ACCOUNT_APP_CODE + "/download-document.svg" ;
    },
  },

  created() {
    this.financialGroupId = this.$util.getSession()
      && this.$util.getSession().financialGroup
        ? this.$util.getSession().financialGroup.id
        : null;
  },
}