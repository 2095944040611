<template>
  <v-container fluid grid-list-md fill-height style="background-color: #F0F0F0;">
    <v-layout row wrap>
      <v-container
        fluid
        class="pb-0">
        <div
          v-if="showTicketForm"
          style="width: 100%;"
          class="elevation-4">
          <v-card class="pa-4 rounded-lg">
            <v-form
              ref="NewTicketForm">
              <v-row>
                <v-col
                  cols="6"
                  xl="5"
                  lg="6">
                  <v-select
                    v-model="ticket.solicitation"
                    :items="solicitations"
                    item-text="name"
                    item-key="code"
                    label="Solicitação*"
                    outlined
                    return-object
                    :rules="[ formrules.required ]"
                  />
                </v-col>

                <v-col
                  cols="6"
                  xl="5"
                  lg="6">
                  <v-autocomplete
                    v-model="ticket.contract"
                    :items="contracts"
                    label="Contrato*"
                    item-text="label"
                    item-key="id"
                    outlined
                    return-object
                    :rules="[ formrules.required ]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  class="py-0">
                  <v-textarea
                    outlined
                    label="Descrição*"
                    v-model="ticket.description"
                    :rules="[ formrules.required ]"
                  />
                </v-col>

                <v-col
                  cols="12">
                  <form-file-upload
                    ref="customFileUpload"
                    :maximum="10"
                    :limit-file-size="12"
                    @update="setAttachments"
                  />
                </v-col>
              </v-row>
            </v-form>

            <v-card-actions class="justify-end">
              <v-btn
                @click="resetTicketFlow"
                class="rounded-lg black--text mr-4"
                color="secondary"
                style="width: 200px; height: 44px;">
                Cancelar
              </v-btn>

              <v-btn
                @click="createCase"
                class="rounded-lg white--text"
                color="#5555A5"
                style="width: 200px; height: 44px;">
                Enviar
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>

        <v-row
          class="px-3" style="min-width: 100%;" v-else>
          <v-col cols="6">
            <v-card
              class="rounded-lg pa-8 fill-height elevation-4"
              style="height: calc(-170px + 100vh);">
              <v-btn
                @click="toggleNewServiceForm"
                class="rounded-lg white--text mb-4"
                color="#5555A5"
                style="width: 230px; height: 44px;">
                iniciar atendimento
              </v-btn>

              <p
                class="font-weight-bold mb-4"
                style="font-size: 16px;">
                Conheça seu time de apoio
              </p>

              <div
                class="overflow-y-auto"
                style="height: calc(-340px + 100vh);">
                <div
                  v-for="(member, index) in supportTeam" :key="member.id">
                  <v-sheet
                    class="lighteen-2 rounded-pill"
                    :color="index % 2 === 0 ? '#E7E7FA' : '#D8EAE8'">
                    <v-container
                      fluid
                      class="pa-1">
                      <v-row
                        no-gutters>
                        <v-col
                          style="max-width: 70px;">
                          <v-avatar size="60">
                            <v-img :src="setUserImage(member.userImage)" />
                          </v-avatar>
                        </v-col>

                        <v-col
                          class="d-flex flex-column justify-center">
                          <span>
                            {{ member.userName }}
                          </span>
                          <span>
                            {{member.jobName}}
                          </span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-sheet>
                  <br>
                </div>
              </div>
            </v-card>
          </v-col>

          <v-col cols="6" v-if="!showServiceDetails">
            <v-card
              class="rounded-lg pa-8 elevation-4"
              style="height: calc(-170px + 100vh);">
              <p
                class="font-weight-bold mb-6">
                Histórico
              </p>

              <v-text-field
                outlined
                dense
                label="Busca..."
                class="rounded-pill mb-2"
                v-model="search"
                hide-details
                @input="debounceSearch"
                :loading="loadingSearch">
                <template v-slot:append>
                  <v-icon
                    color="#5555A5"
                    class="serviceSearchIcon">
                    fas fa-search
                  </v-icon>
                </template>
              </v-text-field>

              <v-autocomplete
                outlined
                dense
                v-model="searchStatus"
                label="Filtrar Status"
                :items="ticketStatus"
                class="rounded-pill"
                item-text="label"
                item-value="code"
                hide-details
                style="max-width: 195px;"
                clearable
                @input="getImplantationSearch"
                :loading="loadingSearch"
              />

              <v-container
                class="mt-4 overflow-y-auto"
                style="height: calc(-420px + 100vh);">
                <v-row v-for="ticket in ticketsSearch" :key="ticket.id">
                  <v-sheet
                    style="width: 100%; border-bottom: 2px solid #E8E8E8;">
                    <v-container
                      fluid>
                      <v-row
                        no-gutters>
                        <v-col
                          cols="6">
                          <span>
                            {{ ticket.name }}
                          </span>
                        </v-col>

                        <v-col
                          cols="6"
                          class="d-flex justify-end">
                          <span
                            style="color: #808080">
                            {{ setDate(ticket.create_date) }}
                          </span>
                        </v-col>

                        <v-col
                          cols="6">
                          <span
                            style="color: #808080">
                            Protocolo {{ ticket.id }}
                          </span>

                          <v-chip
                            :color="getTicketStatus(ticket.state).color"
                            :text-color="getTicketStatus(ticket.state).textColor">
                            {{ getTicketStatus(ticket.state).label }}
                          </v-chip>
                        </v-col>

                        <v-col
                          cols="6"
                          class="d-flex justify-end">
                          <v-btn
                            color="#5555A5"
                            icon>
                            <v-icon
                              @click="toggleShowServiceDetails(ticket)"
                              size="11">
                              fas fa-chevron-right
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-sheet>
                </v-row>
              </v-container>
              <v-pagination
                v-model="currentPage"
                :length="totalPages"
                :total-visible="pageSize"
                @input="getImplantationCase(defaultFieldsTicket)"
              />
            </v-card>
          </v-col>

          <v-col cols="6" v-else>
            <v-card
              class="rounded-lg elevation-4 fill-height d-flex align-content-space-between flex-wrap"
              style="min-width: 100%;">
              <div
                style="min-width: 100%;">
                <v-sheet
                  color="#F6F2FC"
                  width="100%"
                  height="75px"
                  class="d-flex align-center pl-4 rounded-t-lg"
                  style="min-width: 100%;">
                  <ActionBtn
                    :icon="'fas fa-arrow-left'"
                    @click="hideServiceDetails"
                  />
                </v-sheet>

                <div
                  class="pa-8 fill-height overflow-y-auto"
                  style="min-width: 100%; max-height: calc(-340px + 100vh);">
                  <v-row
                    no-gutters
                    class="d-flex flex-column"
                    >
                    <h3
                      class="font-weight-bold"
                      style="font-size: 16px;">
                      Detalhe do Atendimento
                    </h3>


                    <v-card
                      color="#EFF8F6"
                      flat
                      class="rounded-lg"
                    >
                      <v-card-text class="py-2 px-3">
                        <v-row no-gutters>
                          <v-col cols="11">
                            <div class="mb-3" style="gap: 0">
                              <p style="color: #808080">Solicitação</p>
                              <p class="paragraphDetailsText mb-0"><b>
                                {{ currentCase.ticketType }}
                              </b></p>
                            </div>
                            <div v-show="expandServiceDetails">
                              <div class="mb-3">
                                <p>Protocolo</p>
                                <p class="paragraphDetailsText"><b>
                                  {{ currentCase.id }}
                                </b></p>
                              </div>

                              <div class="mb-3">
                                <p>Status</p>
                                <p class="paragraphDetailsText"><b>
                                  {{ currentCase.status }}
                                </b></p>
                              </div>

                              <div class="mb-3">
                                <p>Contrato</p>
                                <p class="paragraphDetailsText"><b>
                                  {{ currentCase.accountName }}
                                </b></p>
                              </div>

                              <div class="mb-3">
                                <p>Beneficio</p>
                                <p class="paragraphDetailsText"><b>
                                  {{ currentCase.benefit }}
                                </b></p>
                              </div>

                              <div class="mb-3">
                                <p>Abertura</p>
                                <p class="paragraphDetailsText"><b>
                                  {{ currentCase.createDate }}
                                </b></p>
                              </div>

                              <div>
                                <p>Descrição</p>
                                <p class="paragraphDetailsText"><b>
                                  {{ currentCase.description }}
                                </b></p>
                              </div>
                            </div>
                          </v-col>
                          <v-col cols="1" class="d-flex align-start justify-end">
                            <button @click="toggleExpandedServiceDetails()" class="mt-1">
                              <font-awesome-icon :icon="['fas', 'chevron-down']" size="lg"/>
                            </button>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                    <h3
                      class="font-weight-bold mt-4"
                      style="min-width: 100%; font-size: 16px;">
                      Eventos
                    </h3>

                    <div
                      style="min-width: 100%;"
                      class="overflow-y-auto"
                      id="serviceHistoricBody">
                      <div
                        style="width: 100%;"
                        v-for="log in serviceHistoric"
                        :key="log.id">
                        <p
                          class="text-subtitle-2"
                          style="color: #808080">
                          {{ setCaseDate(log.create_date) }}
                        </p>

                        <v-sheet
                          class="d-flex flex-column mb-4"
                          :style="setLogClassByOrigin(log.email_from)">
                          <span v-html="log.body"></span>

                          <v-chip
                            v-for="attch in log.attachment_ids"
                            :key="attch.id"
                            @click="downloadFile(attch)"
                            color="#5DAAF5"
                            text-color="white"
                            class="text-truncate overflow-hidden my-1"
                            style="max-width: 400px;">
                            <v-icon
                              size="20"
                              color="white"
                              class="mr-2">
                              fas fa-paperclip
                            </v-icon>
                            {{ attch.name }}
                          </v-chip>
                        </v-sheet>
                      </div>
                    </div>
                  </v-row>
                </div>
              </div>

              <v-sheet
                color="#F6F2FC"
                width="100%"
                min-height="75"
                class="d-flex align-center justify-center flex-row align-center px-4 mt-auto">
                <v-container
                  fluid
                  class="fill-height">
                  <v-row
                    class="d-flex align-center">
                    <v-menu
                      offset-y
                      top>
                      <template
                        v-slot:activator="{ on, attrs }">
                        <ActionBtn
                          :on="on"
                          :attrs="attrs"
                          :icon="'fas fa-plus'"
                          :disabled="currentCase.state === 'closed'"
                        />
                      </template>

                      <v-card
                        height="auto"
                        width="420"
                        color="#29252F">
                        <v-container
                          fluid>
                          <v-row
                            class="d-flex justify-center align-center">
                            <v-col
                              v-for="(action, key) in caseActions"
                              :key="key"
                              cols="4"
                              class="d-flex flex-column justify-center align-center"
                              @click="action.function">
                              <v-btn
                                outlined
                                width="100"
                                height="100"
                                color="secondary">
                                <v-icon
                                  size="50">
                                  {{ action.icon }}
                                </v-icon>
                              </v-btn>

                              <span class="secondary">
                                {{ action.label }}
                              </span>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-menu>

                    <v-text-field
                      outlined
                      hide-details
                      label="Digite sua mensagem..."
                      class="rounded-pill mx-2"
                      v-model="currentCase.message"
                      :disabled="currentCase.state === 'closed'"
                      @keyup.enter="createCaseEvent"
                    />

                    <ActionBtn
                      :icon="'mdi-send'"
                      :disabled="(!currentCase || (currentCase && currentCase.message.length === 0 && currentCase.files.length === 0)) || currentCase.state === 'closed'"
                      :color="!currentCase || (currentCase && currentCase.message.length === 0 && currentCase.files.length === 0) ? '#808080' : '#5555A5'"
                      @click="createCaseEvent"
                    />
                  </v-row>
                  <v-row>
                    <FileDropzone
                      :showOptions="false"
                      :filesImported="currentCase.files"
                      @showFileTypeMessageError="showFileTypeMessageError($event)"
                      @files="currentCase.files = $event"
                    />
                  </v-row>
                </v-container>
              </v-sheet>
            </v-card>
          </v-col>

        </v-row>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
/* eslint-disable */
import FileDropzone from "../../components/file-dropzone/fileDropzone.vue";
import FormFileUpload from "../../components/generic-upload/FormFileUpload";
import RequestMixin from '@/view/atendimento/functions/requestMixin';
import DocumentsMixins from "../../shared/mixins/documentsMixins";
import { mapState, mapGetters, mapMutations } from "vuex";
import AttachmentMixin from "./functions/attachmentMixin";
import ActionBtn from "../sendDocuments/actionBtn.vue";
import { formrules } from "./../../common/formrules";
import FormMixin from "./functions/formMixin";
import { debounce } from "lodash";
import moment from 'moment';
import Vue from "vue";

export default {
  name: "AtendimentoImplantacao",
  components: {
    FormFileUpload,
    FileDropzone,
    ActionBtn,
  },
  data: () => ({
    formrules,
    cards: [],
    selectedTab: "active",
    showTicketForm: false,
    showServiceDetails: false,
    ticket: {
      solicitation: null,
      contract: null,
      description: null,
      files: [],
    },
    currentCase: {
      id: null,
      files: [],
      message: "",
      ticketType: "",
      state: null,
    },
    search: null,
    searchStatus: null,
    userEmail: null,
    serviceHistoric: [],
    solicitations: [],
    supportTeam: [],
    contracts: [],
    tickets: [],
    currentPage: 1,
    totalPages: 1,
    pageSize: 10,
    expandServiceDetails: false,
    debounceSearch: null,
    loadingSearch: false,
  }),

  mixins: [
    AttachmentMixin,
    DocumentsMixins,
    RequestMixin,
    FormMixin,
  ],

  methods: {
    ...mapMutations(["loading", "showmsg"]),

    setAttachments(attachments) {
      this.ticket.files = attachments;
    },

    toggleNewServiceForm() {
      if (this.contracts && this.contracts.length === 1) {
        this.ticket.contract = this.contracts[0];
      }

      this.showTicketForm = !this.showTicketForm;
    },

    hideServiceDetails() {
      this.showServiceDetails = false;

      this.currentCase = {
        id: null,
        files: [],
        message: "",
        ticketType: "",
        statstateus: null,
      };

      this.serviceHistoric = [];

      this.resetCurrentCaseForm();
    },

    async toggleShowServiceDetails(ticket) {
      this.expandServiceDetails = false;
      this.showServiceDetails = !this.showServiceDetails;

      this.currentCase.id = ticket.id;
      this.currentCase.description = ticket.description;
      this.currentCase.ticketType = ticket.ticket_type_id;
      this.currentCase.state = ticket.state;
      
      this.currentCase.createDate = Vue.filter('formatDate')(ticket.create_date);
      this.currentCase.accountName = ticket.partner_id.name;
      this.currentCase.status = ticket.xipp_helpdesk_display_stage ? ticket.xipp_helpdesk_display_stage : ticket.stage_id;

      const contract = ticket.xipp_contract_id;
      if (contract) {
        this.currentCase.benefit = ticket.xipp_benefit_id ? ticket.xipp_benefit_id : "";
        this.currentCase.benefit += contract.carrier_id ? " / " + contract.carrier_id.xipp_commercial_name : " ";
        if (contract.policy) {
            this.currentCase.accountName = contract.policy + " - " + this.currentCase.accountName;
        }
      }
      
      await this.loadServiceComents(ticket);
    },

    toggleExpandedServiceDetails() {
      this.expandServiceDetails = !this.expandServiceDetails
    },

    formatContractText(contract){
      return (`${(contract.benefit_id ? contract.benefit_id : '-')} / ${(contract.carrier_id && contract.carrier_id.xipp_commercial_name ?
          contract.carrier_id.xipp_commercial_name : '-')} - ${contract.contract_owner_id && contract.contract_owner_id.legal_name ? contract.contract_owner_id.legal_name : '-'}`)
    },

    setCaseDate(dateString) {
      return moment(
        dateString.replace(" ", "T") + '.000Z'
      ).format("DD/MM/YYYY HH:mm");
    },

    setLogClassByOrigin(email_from) {
      if (email_from !== this.userEmail) {
        return "background-color: #EFF8F6; color: black; padding: 15px; border-radius: 25px 25px 25px 0px; !important"
      } else {
        return "background-color: #6F71B1; color: white; padding: 15px; border-radius: 25px 25px 0px 25px; !important"
      }
    },

    setUserImage(userImage) {
      return `data:image/png;base64,${userImage}`;
    },

    setDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },

    getTicketStatus(state) {
      switch (state) {
        case 'draft':
          return {
            label: "Novo",
            color: "#CDF9D9",
            textColor: "#154B24"
          };

        case 'in_progress':
          return {
            label: "Em Andamento",
            color: "#CBE2F5",
            textColor: "#14497C"
          };

        case 'closed':
          return {
            label: "Finalizado",
            color: "#e3bb19",
            textColor: "#47453b"
          };
      }
    },

    async getImplantationSearch() {
      this.loadingSearch = true;
      this.currentPage = 1;
      await this.getImplantationCase(this.defaultFieldsTicket, this.searchStatus, this.search)
      this.loadingSearch = false;
    },
  },

  computed: {
    ...mapState(["window"]),
    ...mapGetters(["user"]),

    ticketStatus() {
      return [
        {
          code: "draft",
          label: "Novo",
        },
        {
          code: "in_progress",
          label: "Em Andamento",
        },
        {
          code: "closed",
          label: "Finalizado",
        },
      ]
    },

    ticketsSearch() {
      return this.tickets;
    },

    caseActions() {
      return [
        {
          label: "Imagem",
          icon: "far fa-file-image",
          function: () => this.openFileSelector('.jpg, .jpeg, .png')
        },
        {
          label: "Arquivo",
          icon: "far fa-file",
          function: () => this.openFileSelector('.pdf, .csv, .xls, .xlsx, .txt')
        },
        // {
        //   label: "Câmera",
        //   icon: "fas fa-camera",
        //   function: () => this.stopCamera()
        // },
      ]
    },
  },

  created() {
    this.getContracts(this.defaultFieldsContract);

    this.debounceSearch = debounce(this.getImplantationSearch, 2000);
  },

  async mounted() {
    this.loading(true);

    this.userEmail = this.$util.getSession()
      && this.$util.getSession().email
        ? this.$util.getSession().email
        : null;

    try {
      const [
        tickets,
        solicitations,
        supportTeam,
      ] = await Promise.all([
        this.getImplantationCase(this.defaultFieldsTicket),
        this.getTicketTypes(),
        this.getSupportTeam(),
      ]);

      this.solicitations = solicitations;
      this.supportTeam = supportTeam;
      this.tickets = tickets;

      this.contracts.forEach((contract) => {
        contract.label = this.formatContractText(contract);
      })
    }
    catch(err) {
      this.showmsg({ text: "Ocorreu um erro ao buscar os dados da página!", type: 'error' });
    }

    this.loading(false);
  },
};
</script>
<style scoped>
.serviceSearchIcon {
  font-size: 20px !important;
}

</style>
