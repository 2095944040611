import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,_vm._l((_vm.steps),function(step,stepIndex){return _c(VCol,{key:`${stepIndex}-step`,staticClass:"pa-0 d-flex flex-column justify-center align-center",staticStyle:{"width":"100%","min-width":"50px","height":"200px"}},[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"height":"60px","position":"relative","width":"100%"}},[_c(VSheet,{staticClass:"d-flex justify-center align-center rounded-circle",staticStyle:{"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)","z-index":"2"},style:(`border: ${_vm.sheetStyle(step, stepIndex)}`),attrs:{"height":"60","width":"60","color":_vm.stepValidation(step, stepIndex)
              ? _vm.showSelector
                ? '#60D3C7'
                : '#D8EAE8'
              : 'white'}},[_c(VImg,{attrs:{"height":"34","max-height":"34","max-width":"34","contain":"","src":_vm.setStepperIcon(_vm.stepValidation(step, stepIndex) ? step.code : `${step.code}-alt`)}})],1),(stepIndex < _vm.steps.length -1)?_c(VDivider,{style:(`
            width: 100%;
            z-index: 1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(0%) translateY(-50%);
            border-width: medium;
            border-color: ${_vm.stepBarValidation(step) ? '#60D3C7' : '#E0E0E0'};`)}):_vm._e()],1),_c('div',{class:{
          'd-flex flex-column': true,
          'align-center justify-center': _vm.inModal,
          'align-center justify-start mt-2': !_vm.inModal,
        },style:(!_vm.showSelector
            ? 'height: 160px; width: 100%;'
            : _vm.inModal
              ? 'height: 80px;'
              : 'height: 100px;')},[_c('p',{staticClass:"font-weight-medium text-center",style:(`color: ${_vm.stepValidation(step, stepIndex)
              ? 'black'
              : '#808080'
            }; max-height: 50px;
            ${
              !_vm.inModal
                ? 'height: 50px; max-width: 120px;'
                : 'height: 30px;'
            }`)},[_vm._v(" "+_vm._s(step.name)+" ")]),(
            _vm.calculateCompletedTasks(step.tasks) !== _vm.calculateTotalTasks(step.tasks)
            && _vm.stepValidation(step, stepIndex))?_c('div',{staticClass:"d-flex flex-column align-center"},[_c('div',{staticStyle:{"max-width":"120px"}},[_c('ProgressLoader',{attrs:{"loaded":_vm.calculeTasksPercentage(step.tasks),"inner":true}})],1),(!_vm.showSelector)?_c('div',{staticStyle:{"min-height":"32px"}},[(_vm.stepValidation(step, stepIndex))?_c(VBtn,{staticClass:"rounded-lg mt-2 white--text",style:({
                maxHeight: '44px',
                maxWidth: _vm.$vuetify.breakpoint.lg ? '140px !important' : '100%',
              }),attrs:{"large":"","color":"#5555A5"},on:{"click":_vm.goToSendDocuments}},[_c('span',{staticClass:"text-capitalize text-subtitle-1",style:(_vm.$vuetify.breakpoint.lg ? 'font-size: 14px !important;' : '')},[_vm._v(" Enviar Documentos ")])]):_vm._e()],1):_vm._e()]):_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"height":"30px"}},[_c('span',{staticStyle:{"color":"#808080"}},[_vm._v(" "+_vm._s((_vm.calculateCompletedTasks(step.tasks)))+"/"+_vm._s(_vm.calculateTotalTasks(step.tasks))+" ")])])]),_c('div',{staticStyle:{"height":"6px","width":"100%"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSelector),expression:"showSelector"}],style:({
            width: '100%',
            borderBottom: _vm.stepDashValidation(stepIndex)? '6px solid #60D3C7' : '',
            borderRadius: _vm.stepDashValidation(stepIndex)? '40px' : '',
            bottom: '-18px',
            position: 'relative',
          })})])])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }