<template>
  <v-container fluid>
    <v-card>
      <Loading/>
      <v-card-title style="color: rebeccapurple; font-weight: bold">
        Relatório de Acessos Eu Protegido Analitico
      </v-card-title>
      <v-card-text>

        <v-row dense>
          <v-btn
            large
            color="primary"
            class="ma-2"
            @click="openSyntheticFilter()"
          >
            Relatório Sintético
          </v-btn>
  
          <v-btn
            large
            color="primary"
            class="ma-2"
            @click="openAnalyticalFilter()"
          >
            Relatório Analítico
          </v-btn>
        </v-row>

        <v-row dense>
          <v-col>
            <v-menu
              ref="pickUntil"
              v-model="pickUntil"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formatDateInput(filter.until)"
                  label="Até"
                  readonly
                  placeholder="Dia/Mês/Ano"
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  :rules="[formrules.required]"
                />
              </template>
              <v-date-picker
                v-model="filter.until"
                scrollable
                no-title
                @close="$refs.pickUntil.save(until)"
              />
            </v-menu>
          </v-col>
          <v-expand-transition>
              <v-col v-if="reportIsAnalytical">
              <v-select
                :items="financialGroupIds"
                v-model="filter.selectedFinancialGroup"
                item-value="id"
                item-text="name"
                clearable
                placeholder="Selecione o Grupo Financeiro"
                outlined
                label="Grupo Financeiro"
                :rules="reportIsAnalytical ? [formrules.required] : []"
              />
            </v-col>
          </v-expand-transition>
        </v-row>

      <v-row>
        <v-col>
            <v-btn
              v-show="this.reportType != []"
              large
              color="primary"
              class="ma-2"
              @click="generateReport"
              :loading="downloadingReports"
              :disabled="downloadingReports"
              >
              Exportar
            </v-btn>
          </v-col>
      </v-row>

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import ContractService from "@/services/odoo/ContractService";
import {formrules} from "@/common/formrules";
import AuthService from "@/services/auth/AuthService";
import moment from 'moment';
import SecurityService from "@/services/security/SecurityService"
import Loading from "@/components/structure/Loading";

export default {
  name: "userAccessAnalyticalReport",
  components: { Loading },
  data: () => ({
    formrules,
    filter: {
      until: null,
      selectedFinancialGroup: false,
    },
    downloadingAnalyticalReport: false,
    downloadingSyntheticReport: false,
    downloadingSDIReport: false,
    reportType: false,

    pickUntil: false,
    options:{},
    items: [],
    financialGroupIds: [],
    rules: formrules,
  }),
  computed: {
    reportIsAnalytical() {
      return this.reportType === 'analytical'
    },

    downloadingReports() {
      if(this.downloadingAnalyticalReport === true | this.downloadingSDIReport === true | this.downloadingSyntheticReport === true) {
        return true
      } else {
        return false
      }
    },
  },

  watch: {
    downloadingReports() {
      if (this.downloadingReports) {
        this.loading(true);
      } else {
        this.loading(false);
      }
    },

  },

  methods: {
    ...mapMutations(["showmsg", "loading"]),

    openSyntheticFilter(){
      this.reportType = 'synthetic';
    },

    openAnalyticalFilter(){
      this.reportType = 'analytical';
    },

    formatDateInput(date) {
      return date ? moment(date).format('DD/MM/YYYY') : '';
    },

    generateReport(){
      if (!this.validateFields()) {
        return
      }
      const params = new URLSearchParams();
      if (this.reportIsAnalytical) {
        
        params.append("endAccessDate", this.filter.until);
        params.append("financialGroups", this.filter.selectedFinancialGroup);
        this.callGetUserAccessAnalyticalReport(params);
      } else {
        params.append("endDate", this.filter.until);
        this.callGetUserAccessSyntheticReport(params);
      }
      this.loading(this.downloadingReports);
    },

    callGetUserAccessAnalyticalReport(params) {
      this.downloadingAnalyticalReport = true;
      this._securityService.getUserAccessAnalyticalReport(params)
        .then(response => {
          this.dowloadReport(response.data, "Relatório Analitico Eu Protegido");
          this.downloadingAnalyticalReport=false;
        })
        .catch((error) => {
          this.showmsg({text: 'Ocorreu um erro ao exportar o relatório analítico.', type: 'error'})
          this.downloadingAnalyticalReport=false;
        })
    },

    callGetUserAccessSyntheticReport(params) {
      this.downloadingSyntheticReport = true;
      this._securityService.getUserAccessSyntheticReport(params)
        .then(response => {
          this.dowloadReport(response.data, "Relatório Eu Protegido");
          this.downloadingSyntheticReport= false;
        })
        .catch((error) => {
          this.showmsg({text: 'Ocorreu um erro ao exportar o relatório analítico.', type: 'error'})
          this.downloadingSyntheticReport= false;
        })
    },

    callGetUserAccessSDIReport(params) {
      this.downloadingSDIReport = true;
      this._securityService.getUserAccessSDIReport(params)
        .then(response => {
          this.dowloadReport(response.data, "Base Beneficiaários SDI");
          this.downloadingSDIReport=false;
        })
        .catch((error) => {
          this.showmsg({text: 'Ocorreu um erro ao exportar o relatório .', type: 'error'})
          this.downloadingSDIReport=false;
        })
    },

    dowloadReport(data, fileName){
      const blobParts = this.$util.base64ToBytesArray(data);
      const blob = new Blob(blobParts, { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64" })
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
    },

    validateFields() {
      let inputAreFilled = this.filter.until != null;
      if (this.reportIsAnalytical){
        inputAreFilled = this.filter.selectedFinancialGroup != false & inputAreFilled;
      } else {
        inputAreFilled = this.filter.until != null;
      }
      return inputAreFilled
    },

    async loadJWTToken() {
      this._xippAuthService = new AuthService();
      await this._xippAuthService.GetTokenFull()
          .then((response) => {
            if(response && response.data) {
              localStorage.setItem("LOCALIZED_TOKEN", response.data.token);
            }
          });
    }
  },

  async created() {
    this._contractService = new ContractService();
    this._securityService = new SecurityService();
    const {token} = this.$route.query;

    if(token) {
      localStorage.setItem("LOCALIZED_TOKEN", token);
      localStorage.removeItem("auth_user");
      localStorage.removeItem("token");
      await this.loadJWTToken();
    }

    this._contractService.FindFinancialGroupPermiteds()
    .then(response => {
      this.financialGroupIds = response.data;
    })
    .catch(() => this.showmsg({text: 'Ocorreu um erro ao carregar os grupos financeiros.', type: 'error'}));
  }
};
</script>
