<template>
  <v-container
    fluid>
    <v-row>
      <v-col
        v-for="(step, stepIndex) in steps"
        :key="`${stepIndex}-step`"
        class="pa-0 d-flex flex-column justify-center align-center"
        style="width: 100%; min-width: 50px; height: 200px;">
        <div
          style="height: 60px; position: relative; width: 100%;"
          class="d-flex flex-row">
          <v-sheet
            height="60"
            width="60"
            class="d-flex justify-center align-center rounded-circle"
            :style="`border: ${sheetStyle(step, stepIndex)}`"
            :color="
              stepValidation(step, stepIndex)
                ? showSelector
                  ? '#60D3C7'
                  : '#D8EAE8'
                : 'white'"
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 2;">
            <v-img
              height="34"
              max-height="34"
              max-width="34"
              contain
              :src="setStepperIcon(stepValidation(step, stepIndex) ? step.code : `${step.code}-alt`)"
            />
          </v-sheet>

          <v-divider
            v-if="stepIndex < steps.length -1"
            :style="`
              width: 100%;
              z-index: 1;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(0%) translateY(-50%);
              border-width: medium;
              border-color: ${stepBarValidation(step) ? '#60D3C7' : '#E0E0E0'};`"
          />
        </div>

        <div
          :class="{
            'd-flex flex-column': true,
            'align-center justify-center': inModal,
            'align-center justify-start mt-2': !inModal,
          }"
          :style="
            !showSelector
              ? 'height: 160px; width: 100%;'
              : inModal
                ? 'height: 80px;'
                : 'height: 100px;'">
          <p
            class="font-weight-medium text-center"
            :style="
              `color: ${stepValidation(step, stepIndex)
                ? 'black'
                : '#808080'
              }; max-height: 50px;
              ${
                !inModal
                  ? 'height: 50px; max-width: 120px;'
                  : 'height: 30px;'
              }`">
            {{ step.name }}
          </p>

          <div
            class="d-flex flex-column align-center"
            v-if="
              calculateCompletedTasks(step.tasks) !== calculateTotalTasks(step.tasks)
              && stepValidation(step, stepIndex)">
            <div
              style="max-width: 120px;">
              <ProgressLoader
                :loaded="calculeTasksPercentage(step.tasks)"
                :inner="true"
              />
            </div>

            <div
              style="min-height: 32px;"
              v-if="!showSelector">
              <v-btn
                large
                :style="{
                  maxHeight: '44px',
                  maxWidth: $vuetify.breakpoint.lg ? '140px !important' : '100%',
                }"
                color="#5555A5"
                class="rounded-lg mt-2 white--text"
                v-if="stepValidation(step, stepIndex)"
                @click="goToSendDocuments">
                <span
                  class="text-capitalize text-subtitle-1"
                  :style="$vuetify.breakpoint.lg ? 'font-size: 14px !important;' : ''">
                  Enviar Documentos
                </span>
              </v-btn>
            </div>
          </div>

          <div
            v-else
            class="d-flex justify-center align-center"
            style="height: 30px;">
            <span
              style="color: #808080;">
              {{(calculateCompletedTasks(step.tasks))}}/{{calculateTotalTasks(step.tasks)}}
            </span>
          </div>
        </div>

        <div
          style="height: 6px; width: 100%;">
          <div
            v-show="showSelector"
            :style = "{
              width: '100%',
              borderBottom: stepDashValidation(stepIndex)? '6px solid #60D3C7' : '',
              borderRadius: stepDashValidation(stepIndex)? '40px' : '',
              bottom: '-18px',
              position: 'relative',
            }">
          </div>
      </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
import ProgressLoader from './progressLoader.vue';
import ImplantationMixins from '../../shared/mixins/implantationMixins';
import DocumentsMixins from '../../shared/mixins/documentsMixins';
export default {
  name: "Stepper",

  components: {
    ProgressLoader,
  },

  mixins: [
    ImplantationMixins,
    DocumentsMixins,
  ],

  props: {
    onlySteps: {
      type: Boolean,
      default: false,
    },

    steps: {
      type: Array,
      required: true,
    },

    showSelector: {
      type: Boolean,
      default: false,
    },

    inModal: {
      type: Boolean,
      default: false,
    }
  },

  methods: {
    stepValidation(step, stepIndex) {
      if (stepIndex === 0) return true;

      const validStepIndices = this.steps
        .filter((step) =>
          this.calculeTasksPercentage(step.tasks) === 100
          || this.calculeTasksPercentage( this.steps[stepIndex-1].tasks) === 100)
        .map((item, index) => index);

      const currentStepPercentage = this.calculeTasksPercentage(this.steps[stepIndex].tasks);

      if (validStepIndices.includes(stepIndex)) return true;

      return currentStepPercentage === 100 ? true : false;
    },

    stepDashValidation(stepIndex) {
      const currentPendingStepIndex = this.steps
        .findIndex((step) => this.calculeTasksPercentage(step.tasks) < 100);

      return currentPendingStepIndex === stepIndex;
    },

    stepBarValidation(step) {
      return this.calculeTasksPercentage(step.tasks) === 100
    },

    sheetStyle(step, stepIndex) {
      return !this.showSelector
        ? this.stepValidation(step, stepIndex)
          ? '3px solid #60D3C7 !important;'
          : '3px solid #E0E0E0 !important;'
        : '3px solid #E0E0E0 !important;'
    }
  },
}
</script>

<style>
.custom-stepper > .v-stepper__header > .v-stepper__step > .v-stepper__step__step {
  display: none !important;
}
</style>
