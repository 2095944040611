import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSnackbar,{attrs:{"content-class":"pr-0 py-0","color":`${_vm.msg.data.type} lighten-1`,"timeout":_vm.msg.timeout,"rounded":"lg","top":""},model:{value:(_vm.msgshow),callback:function ($$v) {_vm.msgshow=$$v},expression:"msgshow"}},[_c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"py-3 d-flex align-center"},[_c('div',{staticClass:"white--text",staticStyle:{"text-align":"justify"},domProps:{"innerHTML":_vm._s(_vm.$util.sanitize(_vm.msg.data.text))}})]),_c(VCol,{staticClass:"d-flex justify-center py-3",staticStyle:{"max-width":"70px"}},[_c(VBtn,{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.hidemsg()}}},[_c(VIcon,{attrs:{"color":`${_vm.msg.data.type} lighten-4`}},[_vm._v(" fas fa-times ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }