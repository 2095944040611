<template>
  <v-container fluid>
    <PreviousPage :route="'usuarios'"/>
    <v-overlay :value="busy" :opacity="0">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
    <GenericFAB
      v-if="$resize && $mq.below(window.mobileSize)"
      icon="fas fa-pencil-alt"
      @open="$router.push(`/usuarios/editar/${selectedUser.id}`)"
    />

    <v-card>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" >
            <v-text-field v-model="selectedUser.name" type="text" label="Nome" readonly outlined/>
          </v-col>
          <v-col cols="12" >
            <v-text-field v-model="selectedUser.email" type="email" label="Email" readonly outlined/>
          </v-col>
          <v-col cols="12" >
            <v-text-field v-model="selectedUser.profilesDescription" label="Perfil" readonly outlined />
          </v-col>
          <v-col cols="12" >
            <v-text-field label="CPF" v-model="selectedUser.cpf" readonly outlined/>
          </v-col>
          <v-col cols="12" >
            <v-text-field label="Status" v-model="selectedUser.activeDescription" readonly outlined/>
          </v-col>
          <v-col cols="12" v-if="selectedUser.contracts">
            <v-treeview
              :items="selectedUser.contracts"
              hoverable
              activatable
              open-on-click
              return-object
              open-all
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import GenericFAB from "@/components/generic-fab/index.vue";
import { getUserById } from "@/common/securityserver";
import ContractService from "../../services/odoo/ContractService";
import PreviousPage from "../../components/structure/previousPage";

export default {
  name: "UsuarioDetalhar",
  components: { GenericFAB, PreviousPage },
  data: () => ({
    selectedUser: {},
    contracts: [],
    dataContracts: [],
    busy: false,
    describe: null
  }),
  computed: {
    ...mapState(["window"]),
    ...mapGetters(["user"]),

    isMobileSize() {
      return this.$resize && this.$mq.below(this.window.mobileSize);
    }
  },
  methods: {
    ...mapMutations(["showmsg"]),
    generateTreeHierarchy(toTraverse) {
      const tree = {
        root: {
          id: "contratos",
          name: "Contratos",
          children: []
        }
      };
      toTraverse.forEach(item => {
        if (item.subContract) {
          const parentContractAdded = tree.root.children.find(
            contract => contract.id === item.parentContract
          );
          if (parentContractAdded) {
            tree.root.children = tree.root.children.filter(
              contract => contract.id !== item.parentContract
            );
          }
          if (!tree[item.parentContract]) {
            tree[item.parentContract] = [item];
          } else {
            tree[item.parentContract].push(item);
          }
        } else {
          tree.root.children.push(item);
        }
      });
      return tree;
    },
    generateTree(toTraverse) {
      if (toTraverse) {
        toTraverse = toTraverse.sort(function(a, b) {
          return a.subContract ? 0 : -1;
        });
      }

      const tree = this.generateTreeHierarchy(toTraverse);

      const finalTree = [tree.root];
      delete tree.root;

      Object.keys(tree).forEach(itemKey => {
        itemKey = parseFloat(itemKey)
        const parentContract = toTraverse.find(
          contract => contract.id === itemKey
        );
        const contactChildren = tree[itemKey].map(child => ({
          id: child.id,
          name: child.name,
          children: child.children
        }));
        finalTree[0].children.push({
          id: itemKey,
          name: parentContract ? parentContract.name : '',
          children: contactChildren
        });
      });

      return finalTree;
    },

    getcontratosUser() {
      this.busy = true;
      if (this.selectedUser.email) {
        this._contractService
          .FindIncludeSubContracts(this.selectedUser.email)
          .then(res => {
            if (res.data.records) {
              const configuredRecords = res.data.records.map(
                (record, index) => {
                  const informationsList = [
                    record.contract_owner_id.name,
                    record.cnpj,
                    record.subcontract
                      ? record.subcontract_number
                      : record.policy,
                    record.carrier_id ? record.carrier_id.xipp_commercial_name : (record.contract_owner_id ?
                        (record.contract_owner_id.xipp_commercial_name ?
                            record.contract_owner_id.xipp_commercial_name : '' ) : ''),
                    record.benefit_id
                  ];
                  return {
                    id: record.id,
                    name: informationsList.join(" - "),
                    children: [],
                    subContract: record.subcontract,
                    parentContract: record.parent_contract
                  };
                }
              );

              var _contratos = [];
              configuredRecords.forEach(_re => {
                // subContract
                if (_re.subContract) {
                  // verifica se esta
                  if (
                    this.dataContracts.some(s => s.id == _re.parentContract)
                  ) {
                    // não duplicar
                    if (!_contratos.some(s => s.id == _re.parentContract)) {
                      // add contrat pai
                      var pai = this.dataContracts.filter(
                        s => s.id == _re.parentContract
                      )[0];

                      const informationsList = [
                        pai.contract_owner_id.name,
                        pai.cnpj,
                        pai.subcontract
                          ? pai.subcontract_number
                          : pai.policy,
                        pai.carrier_id.xipp_commercial_name,
                        pai.benefit_id
                      ];

                      var _infoPai = {
                        id: pai.id,
                        name: informationsList.join(" - "),
                        children: [],
                        subContract: pai.subcontract,
                        parentContract: pai.parent_contract
                      };

                      _contratos.push(_infoPai);
                    }
                  }
                }
              });

              // adicionar filhos
              _contratos.push(...configuredRecords);

              this.selectedUser.contracts = this.generateTree(_contratos);
            }
          })
          .catch(reason => console.log(reason))
          .finally(() => (this.busy = false));
      } else {
        this.busy = false;
      }
    },
    async getContratos() {
      this._contractService
        .FindFinancialGroupContracts(this.user.financialGroup.id)
        .then(res => {
          this.dataContracts = res.data.records;
          const configuredRecords = res.data.records.map((record, index) => {
            const informationsList = [
              record.contract_owner_id.name,
              record.cnpj,
              record.subcontract? record.subcontract_number : record.policy,
              record.carrier_id ? record.carrier_id.xipp_commercial_name : (record.contract_owner_id ?
                  (record.contract_owner_id.xipp_commercial_name ?
                      record.contract_owner_id.xipp_commercial_name : '' ) : ''),
              record.benefit_id
            ];

            return {
              id: record.id,
              name: informationsList.join(" - "),
              children: [],
              subContract: record.subcontract,
              parentContract: record.parent_contract
            };
          });

          this.contracts = this.generateTree(configuredRecords);
          this.getcontratosUser();
        })
        .catch(reason => console.log(reason));
    },
  },

  async beforeMount() {
    this._contractService = new ContractService();

    this.busy = true;
    await getUserById(this.$route.params.userId, this.user.tokenSecurityService)
      .then(user => {
        this.selectedUser = user;
        this.selectedUser.activeDescription = this.selectedUser.active ? "Ativo" : "Inativo";

        let profilesDescription = user.profiles
          .map(function(elem) {
            return elem.name;
          })
          .join(", ");

        this.selectedUser.profilesDescription = profilesDescription;
      })
      .catch(error => {
        this.showmsg({
          text: "Erro ao carregar usuário!",
          type: "error"
        });
      })
      .finally(() => {
        this.getContratos();
        this.busy = false;
      });
  }
};
</script>
