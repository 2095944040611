import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":_vm.dialogWidth,"content-class":"overflow-hidden"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{staticClass:"rounded-lg"},[_c(VToolbar,{staticStyle:{"position":"relative"},attrs:{"width":"100%","color":_vm.color,"height":"79"}},[_c(VRow,{staticClass:"d-flex justify-center"},[(_vm.useBackBtn)?_c(VBtn,{staticClass:"white elevation-4 d-flex justify-center align-center",staticStyle:{"position":"absolute","left":"30px","top":"20%"},attrs:{"icon":"","color":_vm.color,"heiht":"43","max-height":"43","width":"43"},on:{"click":_vm.close}},[_c(VIcon,{attrs:{"size":"30"}},[_vm._v(" mdi-arrow-left ")])],1):_vm._e(),_c('span',{staticClass:"text-h6 white--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.useCloseBtn)?_c(VBtn,{staticClass:"white elevation-4 d-flex justify-center align-center",staticStyle:{"position":"absolute","right":"30px","top":"20%"},attrs:{"icon":"","color":_vm.color,"heiht":"43","max-height":"43","width":"43"},on:{"click":_vm.close}},[_c(VIcon,{attrs:{"size":"30"}},[_vm._v(" mdi-close ")])],1):_vm._e()],1)],1),_c(VCardText,{staticClass:"px-0 pb-0"},[_vm._t("body")],2),_c(VCardActions,{staticClass:"px-3 pb-0"},[_vm._t("action")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }