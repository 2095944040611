import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c('PreviousPage',{attrs:{"route":'contratos'}}),_c(VCard,{attrs:{"loading":_vm.busy}},[(!_vm.busy)?_c(VCardText,[_c(VRow,[_vm._l((_vm.headers),function(header){return [_c(VCol,{key:header.value,staticClass:"px-5 py-0 ma-0",attrs:{"cols":"12","md":"4"}},[_c(VTextField,{staticClass:"textFieldContractDetail",attrs:{"label":header.text,"value":_vm.item[header.value],"readonly":"","dense":"","outlined":""}})],1)]})],2)],1):_vm._e()],1),_c(VCard,{staticClass:"mt-2 px-2 py-0",attrs:{"loading":_vm.busy}},[(!_vm.busy)?_c(VCardText,[_c(VTabs,{staticClass:"pa-0",attrs:{"id":"contractsTabs","background-color":"transparent","show-arrows":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c(VTab,{attrs:{"href":"#tab-readjustment"}},[_vm._v("Reajuste")]),_c(VTab,{attrs:{"href":"#tab-subs"}},[_vm._v("Subs")])],1),_c(VTabsItems,{staticClass:"mt-4",model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c(VTabItem,{attrs:{"value":'tab-subs'}},[_c('SubList',{attrs:{"parentContract":_vm.item.id,"status":this.status}})],1),_c(VTabItem,{attrs:{"value":'tab-readjustment'}},[_c('Readjustment',{attrs:{"contractId":_vm.id}})],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }