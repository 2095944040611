<template>
  <v-container fluid grid-list-md>
    <v-overlay :value="loading" :opacity="0">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
    <v-card v-if="$resize && $mq.above(window.mobileSize)">
      <v-card-title>
        <v-spacer />
        <v-btn color="grey" text icon @click="$router.push('/usuarios/criar')">
          <v-icon>fas fa-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="users"
        hide-default-footer
        disable-pagination
        @click:row="user => $router.push(`/usuarios/usuario/${user.id}`)"
        :loading="loading"
      >
        <template v-slot:item.actions="{ item }" >
          <div v-show="item.editable">

            <SubMenu
                @select="(selection) => handleSelection(selection, item)"
                :options="subMenuOptions"
                v-show="false"/>
          </div>
          <div v-show="!item.editable">
            -
          </div>

        </template>
        <template v-slot:no-data>Nenhum usuário cadastrado!</template>
        <template v-slot:loading>Carregando</template>
      </v-data-table>
    </v-card>

    <template v-else>
      <GenericFAB @open="$router.push('/usuarios/criar')" />

      <v-data-iterator class="users-mobile" hide-default-footer hide-default-header :items="users" :items-per-page="1000000">
        <template v-slot:default="props">
          <v-row v-for="item in props.items" :key="item.id">
            <v-col cols="12">
              <v-card @click="$router.push(`/usuarios/usuario/${item.id}`)" class="elevation-5">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>Usuário</v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-content>{{ item.name }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Perfil</v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-content>{{ item.profilesDescription }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Aplicativo</v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-content>{{ item.app }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Email</v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-content>{{ item.email }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Ativo</v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-content>{{ item.active ? 'Ativo' : 'Inativo' }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>


                  <v-list-item class="primary--text justify-center text-sm-center">
                      <label class="primary--text">
                      Clique para detalhar
                      </label>
                  </v-list-item>

                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" type="info" v-show="!loading" outlined>Nenhum usuário cadastrado!</v-alert>
        </template>
      </v-data-iterator>
    </template>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import SubMenu from "@/components/sub-menu/index.vue";
import GenericFAB from "@/components/generic-fab/index.vue";
import { findAllUsersByFinancialGroup, findAllDoctorUsersByFinancialGroup } from "@/common/securityserver";

export default {
  name: "Usuario",
  components: { SubMenu, GenericFAB },
  data() {
    return {
      headers: [
        { text: "Usuário", value: "name", name: "name" },
        { text: "Perfil", value: "profilesDescription", sortable: false },
        { text: "Aplicativo", value: "app", sortable: false },
        { text: "Email", value: "email", sortable: false },
        { text: "Ativo", value: "dsactive", sortable: false },
        { text: "", value: "actions", sortable: false, align: "right" }
      ],
      loading: true,
      busy: true,
      search: "",
      subMenuOptions: [{ title: "Editar", value: "edit" }]
    };
  },
  computed: {
    ...mapState(["window", "users"]),
    ...mapGetters(["user"])
  },
  methods: {
    ...mapMutations(["showmsg"]),
    handleSelection(selectionType, itemSelected) {
      const functionsMap = {
        edit: this.editUser
      };
      functionsMap[selectionType](itemSelected);
    },
    editUser(user) {
      this.$router.push(`/usuarios/editar/${user.id}`);
    },
    prepareUsers(users){
      const finalItems = [];
      users.forEach((user, index) => {
        let profilesDescription = user.profiles
            .map(function(elem) {
              return elem.name;
            })
            .join(", ");
        finalItems.push({
          id: user.id,
          name: user.name,
          profiles: user.profiles,
          profilesDescription: profilesDescription,
          app: this.getAppDescription(user.appCode),
          status: user.status,
          email: user.email,
          cpf: user.cpf,
          active: user.active,
          dsactive: user.active ? 'Ativo' : 'Inativo',
          editable: user.appCode == process.env.VUE_APP_ACCOUNT_APP_CODE,
          contractsIds: user.externalContractIds,
          contractBag: user.contractBag
        });

      });
      return finalItems;
    },
    orderUsers(users){
      users.sort((userA, userB)=>{
        return userA.name.localeCompare(userB.name)
      })
      this.$store.commit("setUsers", [...users]);
    },
    getAppDescription(appCode){
      switch (appCode){
        case "rh_protegido":
          return "RH Protegido";
        case "dr_protegido":
          return "Dr Protegido";
        default:
          return ""
      }
    }
  },
  async beforeMount() {
    try{
      this.$store.commit("setUsers", []);
      const companyUsers =  await findAllUsersByFinancialGroup(this.user.tokenSecurityService);
      const doctorUsers =  await findAllDoctorUsersByFinancialGroup(this.user.tokenSecurityService);
      const users = [...companyUsers, ...doctorUsers];
      this.orderUsers(this.prepareUsers(users));
    }catch(error){
          this.showmsg({
            text: "Erro ao carregar",
            type: "error"
          });
    }finally {
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.users-mobile {
  padding-bottom: 100px;
}
</style>
