<template>
  <iframe
    class="contrastHard"
    src="https://apps.xipp.com.br/rhprotegido/imagens/rhprotegido_background.png"
    height="100%"
    width="100%"
    scrolling="auto"
    frameborder="0"
  />
</template>
<script>
import SecurityService from "@/services/security/SecurityService";
import ContractService from "../../services/odoo/ContractService";
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
  data: () => ({
    busy: false,
    securityService: null,
    tourEnded: false,
  }),

  computed: {
    ...mapState(["window", "users", "session"]),
    ...mapGetters(["user", "getDoneTour"]),
  },

  methods: {
    ...mapMutations(["setDoneTour"]),

    async getTourStatus() {
      await this.securityService.getCurrent()
        .then((response) => response.data)
        .then((result) => this.tourEnded = result.user.doneTour);

      if (!this.tourEnded && this.getDoneTour === false) {
        this.$router.push('/tour')
      }
    },

    handlePageRefresh() {
      sessionStorage.setItem('doneTour', JSON.stringify(this.getDoneTour))
    },

    setSessionStoreData() {
      if(sessionStorage.getItem('doneTour')) {
        this.setDoneTour(JSON.parse(sessionStorage.getItem('doneTour')));
        sessionStorage.removeItem('doneTour')
      }
    },
  },

  async created() {
    this._contractService = new ContractService();
    this.securityService = new SecurityService();
  },

  beforeMount() {
    window.addEventListener('beforeunload', this.handlePageRefresh);
    this.setSessionStoreData();

    if (!this.session.menu.some((item) => item.link === 'home')) {
      this.$router.push('/onboarding')
    }
  },

  async mounted() {
    await this.getTourStatus();
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handlePageRefresh);
  },
};
</script>