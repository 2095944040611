<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-sheet
        v-bind="attrs"
        v-on="on"
        class="rounded-circle d-flex justify-center align-center"
        min-height="22"
        min-width="22"
        style="border: 2px solid #e0e0e0"
        :color="color"
      >
        <v-icon
          color="white"
          size="10"
        >
          {{ icon }}
        </v-icon>
      </v-sheet>
    </template>

    <span>
      {{ status }}
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: "StatusTag",

  props: {
    status: {
      type: String,
      required: true,
    },

    color: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      required: true,
    },
  },
};
</script>
