<template>
  <v-container fluid>
    <v-card>
      <v-card-title style="color: rebeccapurple; font-weight: bold">
        Relatório Questionário de Saúde
      </v-card-title>
      <v-card-text>
        <v-form id="searchForm" ref="searchForm" @submit.prevent="searchItems">
          <v-row dense>
            <v-col cols="3">
              <v-select
                  :items="financialGroups"
                  :loading="createdLoading"
                  v-model="filter.financialGroupId"
                  label="Grupo Financeiro"
                  item-value="id"
                  item-text="name"
                  placeholder="Selecione o Grupo Financeiro"
                  outlined
                  clearable
                  @change="changeFinancialGroup"
                  :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="3">
              <v-select
                  :items="benefits"
                  :loading="contractLoading"
                  v-model="benefitIds"
                  label="Benefício"
                  item-value="id"
                  item-text="name"
                  placeholder="Selecione o Benefício"
                  outlined
                  clearable
                  multiple
              />
            </v-col>
            <v-col cols="2">
              <v-select
                  :items="carriers"
                  :loading="contractLoading"
                  v-model="carrierIds"
                  label="Operadora"
                  item-value="id"
                  item-text="name"
                  placeholder="Selecione a Operadora"
                  outlined
                  clearable
                  multiple
              />
            </v-col>
            <v-col cols="4">
              <v-select
                  :items="clients"
                  :loading="clientLoading"
                  v-model="filter.clientId"
                  label="Cliente"
                  item-value="id"
                  item-text="name"
                  clearable
                  placeholder="Selecione o Cliente"
                  outlined
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="3">
              <v-select
                  :items="contracts"
                  :loading="contractLoading"
                  v-model="filter.contractId"
                  label="Contrato"
                  item-value="id"
                  item-text="name"
                  placeholder="Selecione o Contrato"
                  outlined
                  clearable
                  @change="changeContract"
              />
            </v-col>
            <v-col cols="3">
              <v-select
                  :items="subContracts"
                  :loading="subContractLoading"
                  v-model="filter.subContractId"
                  label="SubContrato"
                  item-value="id"
                  item-text="name"
                  clearable
                  placeholder="Selecione o SubContrato"
                  outlined
              />
            </v-col>
            <v-col cols="2">
              <v-select
                  :items="campaigns"
                  :loading="campaignLoading"
                  v-model="filter.campaignId"
                  label="Campanha"
                  item-value="id"
                  item-text="name"
                  clearable
                  placeholder="Selecione a Campanha"
                  outlined
              />
            </v-col>
            <v-col cols="2">
              <v-menu
                  ref="menuInitialDate"
                  v-model="menuInitialDate"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      @click="menuInitialDate = true"
                      :value="formatInitialDateInput"
                      :rules="[rules.required]"
                      label="Período Inicial"
                      readonly
                      placeholder="Data início"
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      outlined
                  />
                </template>
                <v-date-picker
                    v-model="filter.initialDate"
                    no-title
                    scrollable
                    @close="$refs.menuInitialDate.save(filter.initialDate)"
                />
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                  ref="menuFinalDate"
                  v-model="menuFinalDate"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      @click="menuFinalDate = true"
                      :value="formatFinalDateInput"
                      :rules="[rules.required]"
                      label="Período Final"
                      readonly
                      placeholder="Data fim"
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      outlined
                  />
                </template>
                <v-date-picker
                    v-model="filter.finalDate"
                    no-title
                    scrollable
                    :max="maxEndDate"
                    :min="filter.initialDate"
                    @close="$refs.menuFinalDate.save(filter.finalDate)"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="10" class="text-right pt-1">
            </v-col>
            <v-col cols="1" class="text-right pt-1">
              <v-btn
                  large
                  color="primary"
                  :loading="loadingSearch"
                  :disabled="loadingSearch"
                  type="submit"
              >Pesquisar</v-btn
              >
            </v-col>
            <v-col v-if="items.length>0" cols="1" class="text-left pt-1">
              <v-btn
                  v-if="items.length>0"
                  large
                  color="primary"
                  :loading="loadingSearch"
                  :disabled="loadingSearch"
                  @click="generateReport"
              >Exportar</v-btn
              >
            </v-col>
          </v-row>
        </v-form>

        <v-data-table
            id="search-result"
            loading-text="Pesquisando registros..."
            disable-filtering
            disable-sort
            :headers="headers"
            :items="items"
            :loading="loadingSearch"
            :items-per-page="itemsPerPage"
            :page="page"
            item-key="id"
            :footer-props="{
                        itemsPerPageOptions: [10,20,-1]
                      }"
        >
          <template v-slot:header>
          </template>
          <template v-slot:no-data>
            <v-alert
                :value="true"
                type="info"
                v-show="!loadingSearch"
                outlined
            >Nenhum item foi encontrado</v-alert>
          </template>
          <template v-slot:item.birthdayDate="{ item }">
            {{ new Date(item.birthdayDate).toLocaleDateString() }}
          </template>
          <template v-slot:item.contractName="{ item }">
            <span v-if="!item.contractName"> - </span>
            <span v-else> {{item.contractName}} </span>
          </template>
          <template v-slot:item.subContractName="{ item }">
            <span v-if="!item.subContractName"> - </span>
            <span v-else> {{item.subContractName}} </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import moment from "moment/moment";
import ContractService from "@/services/odoo/ContractService";
import SurveyService from "@/services/survey/SurveyService";
import SecurityService from "@/services/security/SecurityService"
import {formrules} from "@/common/formrules";
import AuthService from "@/services/auth/AuthService";

export default {
  name: "RelatorioEvolucao",
  data: () => ({
    filter: {
      financialGroupId: null,
      benefitIds: null,
      carrierIds: null,
      clientId: null,
      contractId: null,
      subContractId: null,
      campaignId: null,
      initialDate: null,
      finalDate: null
    },
    page: 1,
    itemsPerPage: 10,
    items: [],
    financialGroups: [],
    benefits: [],
    benefitIds: [],
    carriers: [],
    carrierIds: [],
    clients: [],
    contracts: [],
    subContracts: [],
    campaigns: [],
    menuInitialDate: null,
    menuFinalDate: null,
    createdLoading: false,
    campaignLoading: false,
    clientLoading: false,
    contractLoading: false,
    subContractLoading: false,
    loadingSearch: false,
    rules: formrules,
    headers: [
      { text: "Cliente", value: "clientName", cols: "6" },
      { text: "Plano", value: "planName", cols: "6" },
      { text: "Contrato", value: "contractPolicy", cols: "12", align: 'center' },
      { text: "SubContrato", value: "subContractPolicy", cols: "12", align: 'center' },
      { text: "Nome", value: "name", cols: "6" },
      { text: "CPF", value: "cpf", cols: "6", align: 'center' },
      { text: "Sexo", value: "gender", cols: "6", align: 'center' },
      { text: "Data de Nascimento", value: "birthdayDate", cols: "6", align: 'center' },
      { text: "Idade", value: "age", cols: "6", align: 'center' },
      { text: "Titularidade", value: "ownership", cols: "6" }
    ],
    maxEndDate: null
  }),
  methods: {
    ...mapMutations(["showmsg"]),
    changeFinancialGroup(){
      if(!this.filter.financialGroupId){
        this.benefitIds=null;
        this.filter.benefitIds=null;
        this.carrierIds=null;
        this.filter.carrierIds=null;
        this.filter.clientId=null;
        this.filter.contractId=null;
        this.filter.subContractId=null;
        this.filter.campaignId=null;
        return;
      }

      this.benefits = [];
      this.carriers = [];
      this.contractLoading = true;
      this._contractService.FindAllContractsByFinancialGroupId(this.filter.financialGroupId)
      .then(response => {
        this.contracts = response.data.records.filter(c => !c.subcontract);
        this.filter.contractId = this.contracts.filter(c => c.benefit_id == 'Saúde').map(c => c.id);
        this.filter.contractId = this.filter.contractId.length > 0 ? this.filter.contractId[0] : null;
        this.subContracts = response.data.records.filter(c => c.subcontract && c.parent_contract && this.filter.contractId==c.parent_contract);
        this.filter.subContractId = this.subContracts.filter(c => c.benefit_id == 'Saúde').map(c => c.id);
        this.filter.subContractId = this.filter.subContractId.length > 0 ? this.filter.subContractId[0] : null;
        this.contracts.forEach(c => {

          if(!this.existsIdOnArray(this.benefits,c.benefit_id)){
            this.benefits.push({id: c.benefit_id, name: c.benefit_id})
          }
          this.benefitIds = ['Saúde'];

          if(!this.existsIdOnArray(this.carriers,c.carrier_id.id)){
            this.carriers.push({id: c.carrier_id.id, name: c.carrier_id.xipp_commercial_name})
            if (c.benefit_id=='Saúde') {
              this.carrierIds.push(c.carrier_id.id);
            }
          }
        })
      })
      .catch(e => console.log(e))
      .finally(() => this.contractLoading=false);

      this.campaignLoading = true;
      this._surveyService.findAllByFinancialGroupId(this.filter.financialGroupId)
      .then(response => {
        this.campaigns = response.data;
      })
      .catch(() => this.showmsg(this.defaultErrorObject))
      .finally(() => this.campaignLoading=false);

      this.clientLoading=true;
      this._contractService.findClientsByFinancialGroupId(this.filter.financialGroupId)
      .then(response => {
        this.clients = response.data.records;
      })
      .catch(() => this.showmsg(this.defaultErrorObject))
      .finally(() => this.clientLoading=false);

    },
    existsIdOnArray(array,id){
      array.forEach(a => {
        if(a.id === id)
          return true;
      });
      return false;
    },
    changeContract(){

      if(!this.filter.contractId) {
        this.filter.subContractId = null;
        return;
      }

      this.subContractLoading=true;
      this._contractService.FindSubContracts(this.filter.contractId)
      .then(response => {
        this.subContracts = response.data.records;
        this.filter.subContractId = this.subContracts.filter(c => c.benefit_id == 'Saúde').map(c => c.id);
        this.filter.subContractId = this.filter.subContractId.length > 0 ? this.filter.subContractId[0] : null;
      })
      .catch(() => this.showmsg(this.defaultErrorObject))
      .finally(() => this.subContractLoading=false);

    },
    searchItems(){
      if(!this.searchValidate()){
        return;
      }

      this.loadingSearch=true;
      this.filter.benefitIds=this.benefitIds.length>0 ? this.benefitIds.toString() : null;
      this.filter.carrierIds=this.carrierIds.length>0 ? this.carrierIds.toString() : null;
      this._surveyService.findAllByFilters(this.filter)
      .then(response => {
        this.items = response.data;
      })
      .catch(() => this.showmsg(this.defaultErrorObject))
      .finally(() => this.loadingSearch=false);

    },
    generateReport(){

      if(!this.searchValidate()){
        return;
      }

      this.loadingSearch=true;
      this._surveyService.generateReport(this.filter)
      .then(response => {
        const blobParts = this.$util.base64ToBytesArray(response.data);
        const blob = new Blob(blobParts, { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64" })

        const url = window.URL.createObjectURL(blob);

        // Criar um link para o download e clicar nele para iniciar o download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Relatório de Evolução - Questionário.xlsx';
        link.click();
      })
      .catch(() => this.showmsg(this.defaultErrorObject))
      .finally(() => this.loadingSearch=false);

    },
    searchValidate(){
      if (!this.$refs.searchForm.validate()) {
        setTimeout(this.$refs.searchForm.resetValidation, 3000);
        return false;
      }

      if(moment(this.filter.initialDate, "YYYY-MM-DD").isSameOrAfter(moment(this.filter.finalDate, "YYYY-MM-DD"))){
        this.showmsg({ text: 'A data inicial maior ou igual a data final.', type: 'error' })
        return false;
      }

      return true;
    },
    async loadJWTToken() {
      this._xippAuthService = new AuthService();
      await this._xippAuthService.GetTokenFull()
          .then((response) => {
            if(response && response.data) {
              localStorage.setItem("LOCALIZED_TOKEN", response.data.token);
            }
          });
    }
  },
  async created() {
    this._surveyService = new SurveyService();
    this._contractService = new ContractService();
    this._securityService = new SecurityService();
    const {token} = this.$route.query;
    if(token) {
      localStorage.setItem("LOCALIZED_TOKEN", token);
      localStorage.removeItem("auth_user");
      localStorage.removeItem("token");
      await this.loadJWTToken();
    }
    await this.loadJWTToken();

    var currentUser = this.$util.getSession();
    this._contractService.FindAllFinancialGroup()
    .then(response => {
      this.financialGroups = response.data.records;
    })
    .catch(() => this.showmsg(this.defaultErrorObject))
    .finally(() => this.contractLoading=false);
    this.financialGroups.push(currentUser.financialGroup);
  },
  computed: {
    formatInitialDateInput() {
      return this.filter.initialDate ? moment(this.filter.initialDate).format('DD/MM/YYYY') : '';
    },
    formatFinalDateInput() {
      return this.filter.finalDate ? moment(this.filter.finalDate).format('DD/MM/YYYY') : '';
    }
  },
  watch: {
    'filter.initialDate'(newDate) {
      this.maxEndDate = new Date(newDate);
      this.maxEndDate.setMonth(this.maxEndDate.getMonth() + 6);
      this.maxEndDate = moment(this.maxEndDate).format("YYYY-MM-DD");

      if (this.filter.finalDate && this.filter.finalDate > this.maxEndDate) {
        this.filter.finalDate = null;
      }
    }
  }
};
</script>
