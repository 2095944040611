import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[(_vm.showOptions)?_c('div',{staticClass:"file-dropzone d-flex align-center justify-center",style:(`border: 2px dashed ${_vm.disabled ? 'gray' : _vm.color}; height: ${_vm.height};`),on:{"dragover":function($event){$event.preventDefault();},"drop":_vm.handleDrop,"dragenter":_vm.handleDragEnter,"dragleave":_vm.handleDragLeave}},[_c(VContainer,{staticClass:"d-flex justify-center",staticStyle:{"pointer-events":"none"}},[_c(VRow,{class:`d-flex align-center justify-${_vm.justify}`,style:(`
          pointer-events: none;
          max-width: ${_vm.contentWidth};`),attrs:{"no-gutters":""}},[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"width":"80%"}},[_c('label',{style:(`color: ${_vm.disabled ? 'gray' : _vm.color}`)},[_vm._v(" "+_vm._s(_vm.message)+" ")])]),_c('div',{staticStyle:{"pointer-events":"auto"}},[_c('ActionBtn',{attrs:{"height":_vm.actionBtnHeight,"width":_vm.actionBtnWidth,"icon":'mdi-upload',"color":_vm.disabled ? '#808080' : '#5555A5',"disabled":_vm.disabled},on:{"click":_vm.openFileFolder}})],1)])],1)],1):_vm._e(),_c(VFileInput,{directives:[{name:"show",rawName:"v-show",value:((!Array.isArray(_vm.files) && !!_vm.files) || (Array.isArray(_vm.files) && _vm.files.length > 0)),expression:"(!Array.isArray(files) && !!files) || (Array.isArray(files) && files.length > 0)"}],ref:"FileInput",staticClass:"py-0",style:(`
      overflow: ${_vm.files && _vm.files.length > 3 ? 'auto' : 'hidden'};
      width: 100%;
      max-height: 170px;`),attrs:{"name":"FileDropzone","accept":_vm.acceptFileTypes,"multiple":_vm.isMultiple,"chips":"","hide-details":"","prepend-icon":"","append-icon":"","clearable":false,"truncate-length":"100","disabled":_vm.disabled},on:{"change":_vm.filesChanged},scopedSlots:_vm._u([{key:"selection",fn:function({index, text}){return [_c('div',{staticClass:"d-block my-1",staticStyle:{"width":"100%","pointer-events":"auto"}},[_c(VSheet,{staticClass:"white d-flex align-center px-1",style:(`box-shadow: 0px 3px 6px #270B5226; border-radius: 5px; max-width: ${_vm.maxWidthFile}`),attrs:{"close":"","large":"","height":"40","max-width":"280"}},[_c('div',{staticClass:"d-flex flex-row align-center justify-center px-1",staticStyle:{"height":"30px","width":"100%"}},[_c(VIcon,{staticClass:"mr-1",attrs:{"color":"secondary","size":"17"}},[_vm._v(" fas fa-paperclip ")]),_c('div',{staticClass:"d-flex flex-row align-center justify-space-between px-1 white--text rounded-lg",staticStyle:{"font-size":"12px","width":"100%","height":"100%","background-color":"#5DAAF5"}},[_c('div',{staticClass:"text-truncate mr-1",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(text)+" ")]),_c(VBtn,{staticClass:"white",staticStyle:{"pointer-events":"auto"},attrs:{"fab":"","color":"primary","height":"20","width":"20"},on:{"click":function($event){$event.stopPropagation();return _vm.removeFile(index)}}},[_c(VIcon,{attrs:{"size":"10"}},[_vm._v(" mdi-close ")])],1)],1)],1)])],1)]}}]),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }