<template>
  <v-container>
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="400">
      <v-card>
        <v-card-title class="text-h5 font-weight-bold" style="color: var(--v-primary-base)">
          <v-icon class="pr-1" color="primary">{{ icon }}</v-icon> {{ title }}
        </v-card-title>
        <v-card-text>{{ text }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              style="color: var(--v-primary-base)"
              color="white"
              @click="showDialog = false">
            Cancelar
          </v-btn>
          <v-btn
              color="primary"
              @click="confirmDialog()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

export default {
  name: "dialogDeleteConfirmation",
  data: () => ({
    showDialog: false,
  }),
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'fas fa-exclamation-triangle'
    },
    text: {
      type: String,
      default: 'Você deseja realmente excluir este registro?'
    },
    title: {
      type: String,
      default: 'Atenção'
    }
  },
  watch: {
    show(newValue) {
      this.showDialog = newValue;
    },
    showDialog(newValue) {
      this.$emit('showDialog', newValue);
    }
  },
  methods: {
    confirmDialog() {
      this.showDialog = !this.showDialog;
      this.$emit('confirmDialog');
    }
  },
};
</script>
