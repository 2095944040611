import HttpService from './HttpService';

export default class AppConfigurationService {
  constructor() {
    this._httpService = new HttpService();
  }

  async GetWelcomeText() {
    return await this._httpService.get(`/api/security/app_configuration/getAppConfigurationValueByCode?key=welcome_text&code=rh_protegido&entityType=APP`);
  }

  async GetOperationalMappingText() {
    return await this._httpService.get(`/api/security/app_configuration/getAppConfigurationValueByCode?key=operational_mapping_info&code=rh_protegido&entityType=APP`);
  }

  async GetCheckListDocumentsCustomerText() {
    return await this._httpService.get(`/api/security/app_configuration/getAppConfigurationValueByCode?key=checklist_documents_customer_info&code=rh_protegido&entityType=APP`);
  }

  async GetCheckListDocumentsContractText() {
    return await this._httpService.get(`/api/security/app_configuration/getAppConfigurationValueByCode?key=checklist_documents_contract_info&code=rh_protegido&entityType=APP`);
  }

  async GetCheckListDocumentsBeneficiaryText() {
    return await this._httpService.get(`/api/security/app_configuration/getAppConfigurationValueByCode?key=checklist_documents_beneficiary_info&code=rh_protegido&entityType=APP`);
  }
}
